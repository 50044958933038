import React from 'react';
import { useEffect } from 'react';
import EventEmitter from 'core/utils/events';

const env = process.env;

export const useGenericErrorHandler = () => {
  const [error, setError] = React.useState();

  const handle = (err) => {
    setError(err);
  };

  useEffect(() => {
    EventEmitter.on('GENERIC_ERROR', handle);
    return () => {
      EventEmitter.removeListener('GENERIC_ERROR', handle);
    };
  }, []);

  return { error, setError, handle };
};
