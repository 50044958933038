import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { ActionAlertModal } from './core/components';
import { IWarningModal } from './modules/shared/bidInterfaces';

const env = process.env;
const dsn = env.REACT_APP_SENTRY_DSN;
const transactionId = Math.random().toString(36).substr(2, 5).toUpperCase();

Sentry.init({
  dsn: `${dsn}`,
  beforeSend(event) {
    event.tags = {
      ...event.tags,
      transactionId,
    };
    return event;
  },
});

sessionStorage.setItem('transaction_id', transactionId);

const FallbackComponent = () => {
  return (
    <div>
      {`An unexpected error has occurred. Please contact AFISupport with Error ID: ${sessionStorage.getItem(
        'transaction_id'
      )}`}
    </div>
  );
};

const AppWrapper = (props) => {
  const [warningModal, setWarningModal] = React.useState<IWarningModal>(null);

  window.onerror = (errorMsg, url, lineNumber, column, errorObj) => {
    setWarningModal({
      message: `An unexpected error has occurred. Please contact AFISupport with Error ID: ${sessionStorage.getItem(
        'transaction_id'
      )}`,
      proceed: () => setWarningModal(null),
    });
    return true;
  };

  console.log(Sentry.isInitialized());

  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      <App {...props} />
      {warningModal && (
        <ActionAlertModal
          message={warningModal.message}
          onConfirm={() => setWarningModal(null)}
          isVisible={true}
        />
      )}
    </Sentry.ErrorBoundary>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

serviceWorker.unregister();
