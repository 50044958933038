import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { LoadingModal } from 'core/components';
import {
  NewInspectScreen,
  InspectionMenuScreen,
  ReportPreviewScreen,
} from 'modules/InspectForm/screens';

import { setCookie } from 'core/utils/cookiesUtils';
import {
  PerformanceEvaluation,
  PerformanceEvaluationMenuScreen,
  SummarySuccess as PerformanceSummarySuccess,
} from 'modules/PerformanceEvaluation/screens';

import {
  BidForm,
  Proposal,
  NewTemplate,
  PdfView,
  CustomizeTerms,
  SummarySuccess,
  ProposalDownload,
} from 'modules/BidInfoForm/screens';

import { isWebView } from 'core/utils';

import { BidFormMenuScreen } from 'modules/shared/Menu';

import Thanks from '../modules/BidInfoForm/screens/Thanks';
import ExpiredPage from '../modules/BidInfoForm/screens/Expired';
import { PrintPdf } from '../modules/PerformanceEvaluation/screens/PrintPdf';
import { SpecialtyForm } from '../modules/SpecialtyForm/screens';
import { LoadingPage } from '../modules/BidInfoForm/components/organisms';

import {
  ANAGO_USER_ACCESS_TOKEN,
  ANAGO_CLEANSOURCE_DEV_LOGIN_URL,
} from '../core/utils/constants';
import { getCookie } from '../core/utils/cookiesUtils';
import DownloadPDF from '../modules/BidInfoForm/screens/DownloadPDF';
import { PdfPreview } from '../modules/BidInfoForm/screens/PdfPreview';

const env = process.env;

const Sold = () => (
  <ExpiredPage
    headerMessage="Proposal Already Signed!"
    bodyMessage="The proposal you are trying to access has already been signed. <br/> Please contact your Anago Representative if you have any questions."
    footerMessage="Thank You."
  />
);

const Expired = () => (
  <ExpiredPage
    headerMessage="This Proposal is no longer available."
    bodyMessage="Please contact your local Anago Office should you have any questions."
    footerMessage="Thank You."
  />
);

const UnableToOpen = () => (
  <ExpiredPage
    headerMessage="UNABLE TO FIND DOCUMENT"
    bodyMessage="The document you are searching for could not be found. Please close this window and try again."
    footerMessage="If the problem persists please contact your administrator."
  />
);

const Deprecated = (props) => (
  <ExpiredPage
    deprecatedPage
    invertedMessage={window.innerHeight < 832 && window.innerWidth < 698}
    headerMessage="Attention!"
    bodyMessage="This application has been discontinued. Visit <a href='http://AnagoCleanSource.com?code=print-pdf'>AnagoCleanSource.com</a> to use the new version."
    footerMessage="Thank You."
    {...props}
  />
);

const UserEvaluation = () => <PerformanceEvaluation userEvaluation />;

const PerformanceThanks = (props) => <Thanks fromPerformance {...props} />;

const SpecialtyMenuScreen = (props) => (
  <BidFormMenuScreen isSpecialty title="SPECIALTY SERVICE SHEET" {...props} />
);

const Loading = () => <LoadingPage />;

/*
  /close-form will be the route that the iPad app will be
  listening for close the web view. For while, I just
  leave the loading modal at it, because if the iPad takes
  too long for handle the close, the user will see
  the loading modal as feedback for close.
*/

const PreventDeprecatedRoute = (props) => {
  if (isWebView()) {
    return <Redirect to="/deprecated" />;
  }

  return <Route {...props} />;
};

const ProtectedRoute = (props) => {
  if (getCookie(ANAGO_USER_ACCESS_TOKEN)) {
    return <PreventDeprecatedRoute {...props} />;
  } else {
    setCookie('anago_redirect_after_login', window.location.href);
    window.location.href =
      env.REACT_APP_BACKEND_URL || ANAGO_CLEANSOURCE_DEV_LOGIN_URL;
  }
};

export const Router = () => (
  <Switch>
    {/* <Route path='*' >
      {() => {
        window.location.href = env.REACT_APP_CLEAN_SOURCE_LOGIN_URL || ANAGO_CLEANSOURCE_DEV_LOGIN_URL;;
      }}
    </Route>     */}
    <Route exact={true} path="/deprecated" component={Deprecated} />

    <ProtectedRoute exact={true} path="/" component={NewInspectScreen} />
    <ProtectedRoute exact={true} path="/loading" component={Loading} />
    <ProtectedRoute
      exact={true}
      path="/inspection-form"
      component={NewInspectScreen}
    />
    <ProtectedRoute
      exact={true}
      path="/performance-evaluation"
      component={PerformanceEvaluation}
    />
    <ProtectedRoute
      exact={true}
      path="/performance-evaluation/print-blank-pdf"
      component={PrintPdf}
    />
    <ProtectedRoute
      exact={true}
      path="/performance-evaluation/menu"
      component={PerformanceEvaluationMenuScreen}
    />

    <ProtectedRoute
      exact={true}
      path="/performance-evaluation/summary-success/:id?"
      component={PerformanceSummarySuccess}
    />
    <ProtectedRoute
      exact={true}
      path="/menu"
      component={InspectionMenuScreen}
    />

    <ProtectedRoute
      exact={true}
      path="/customize-terms"
      component={CustomizeTerms}
    />
    <Route
      exact={true}
      path="/report-preview/:id"
      component={ReportPreviewScreen}
    />
    {/* new ProtectedRoutes */}
    <ProtectedRoute
      exact={true}
      path="/inspection-report-form"
      component={NewInspectScreen}
    />
    <ProtectedRoute
      exact={true}
      path="/inspection-report-form/menu"
      component={InspectionMenuScreen}
    />
    <Route
      exact={true}
      path="/inspection-report-form/preview/:id"
      component={ReportPreviewScreen}
    />
    <ProtectedRoute exact={true} path="/bid-info-form" component={BidForm} />
    <ProtectedRoute exact={true} path="/new-template" component={NewTemplate} />
    <ProtectedRoute
      exact={true}
      path="/bid-info-form/summary-success/:id?/:emails?"
      component={SummarySuccess}
    />
    <ProtectedRoute
      exact={true}
      path="/bid-info-form/menu"
      component={BidFormMenuScreen}
    />
    <ProtectedRoute
      exact={true}
      path="/specialty-form/menu"
      component={SpecialtyMenuScreen}
    />
    <ProtectedRoute
      exact={true}
      path="/specialty-form/summary-success/:id?/:emails?"
      component={SummarySuccess}
    />

    <ProtectedRoute
      exact={true}
      path="/bid-info-form/old-pdf-view"
      component={PdfView}
    />
    <ProtectedRoute
      exact={true}
      path="/bid-info-form/new-pdf-view"
      component={PdfView}
    />
    <ProtectedRoute
      exact={true}
      path="/bid-info-form/customize-terms"
      component={CustomizeTerms}
    />
    <ProtectedRoute
      exact={true}
      path="/bid-info-form/pre-filled"
      component={BidForm}
    />
    <ProtectedRoute
      exact={true}
      path="/specialty-form"
      component={SpecialtyForm}
    />
    <ProtectedRoute
      exact={true}
      path="/specialty-form/pre-filled"
      component={SpecialtyForm}
    />
    <ProtectedRoute
      exact={true}
      path="/forms/unable-to-open"
      component={UnableToOpen}
    />
    <ProtectedRoute
      exact={true}
      path="/proposal/physicalContract"
      component={Proposal}
    />
    <ProtectedRoute
      exact={true}
      path="/proposal/download"
      component={ProposalDownload}
    />

    <Route exact={true} path="/close-form" component={LoadingModal} />
    <Route exact={true} path="/proposal" component={Proposal} />
    <Route exact={true} path="/proposal/thanks" component={Thanks} />
    <Route exact={true} path="/proposal/expired" component={Expired} />
    <Route exact={true} path="/proposal/sold" component={Sold} />
    <Route exact={true} path="/bid-info-form/proposal" component={Proposal} />

    <Route exact={true} path="/pdf/preview" component={PdfPreview} />

    <Route
      exact={true}
      path="/bid-info-form/proposal/thanks"
      component={Thanks}
    />
    <Route
      exact={true}
      path="/user-evaluation/thanks"
      component={PerformanceThanks}
    />
    <Route exact={true} path="/user-evaluation/" component={UserEvaluation} />
  </Switch>
);
