import { validateEmail, validatePhone, validatePostalCode } from './utils';

import { getAlphabetLetter, isNumber } from 'core/utils';
import { setInvalidCleaningSpecs } from 'core/hooks/useInvalidCleaningSpecs';
import EventEmitter from '../utils/events';

export const validateForm = (formValues: any, isSpecialty: boolean = false) => {
  const invalidSpecs = [];
  const subListsOpen = [];

  const ret = [];
  const incomp = [];
  const autoOpenSections = [];

  const validateServiceTitle = () => {
    const serviceTitles = document.querySelectorAll('.service-title-input');
    serviceTitles.forEach((item) => {
      if (item.value === '') {
        item.classList.add('is-invalid');
      }
    });
  };

  const clearServiceTitle = () => {
    const serviceTitles = document.querySelectorAll('.service-title-input');
    serviceTitles.forEach((item) => {
      item.classList.remove('is-invalid');
    });
  };

  var el = document.getElementsByClassName('validate-pid')[0];
  if (!formValues.pid) {
    ret.push('PID');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  el = document.getElementsByClassName('validate-bid-date')[0];

  if (!formValues.bidDate) {
    ret.push('BID DATE');
    el.classList.add('is-invalid');
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  el = document.getElementsByClassName('validate-company-name')[0];

  if (!formValues.businessName) {
    ret.push('LOCATION NAME');
    el.classList.add('is-invalid');
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  if (formValues.decisionMakerEmail) {
    var el = document.getElementsByClassName(
      'validate-decision-maker-email'
    )[0];
    if (!validateEmail(formValues.decisionMakerEmail)) {
      incomp.push('DECISION MAKER EMAIL');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }
  }

  if (formValues.walkThruEmail) {
    var el = document.getElementsByClassName(
      'validate-walkthru-maker-email'
    )[0];
    if (!validateEmail(formValues.walkThruEmail)) {
      incomp.push('WALKTHRU EMAIL');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }
  }

  if (formValues.phone) {
    var el = document.getElementsByClassName('validate-phone')[0];
    if (!formValues.externalId && !validatePhone(formValues.phone)) {
      incomp.push('OFFICE PHONE');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }
  }

  if (formValues.decisionMakerPhone) {
    var el = document.getElementsByClassName('validate-phone-decision')[0];
    if (
      !formValues.externalId &&
      !validatePhone(formValues.decisionMakerPhone)
    ) {
      incomp.push('DECISION MAKER PHONE');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }
  }

  if (formValues.walkThruPhone) {
    var el = document.getElementsByClassName('validate-phone-walk')[0];
    if (!formValues.externalId && !validatePhone(formValues.walkThruPhone)) {
      incomp.push('WALK-THRU PHONE');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }
  }

  // if (formValues.mobilePhone) {
  //   var el = document.getElementsByClassName('validate-mobile-phone')[0];
  //   if (!validatePhone(formValues.mobilePhone)) {
  //     incomp.push('MOBILE PHONE');
  //     if (el) {
  //       el.classList.add('is-invalid');
  //     }
  //   } else {
  //     if (el && el.classList.contains('is-invalid')) {
  //       el.classList.remove('is-invalid');
  //     }
  //   }
  // }

  if (formValues.postalCode) {
    var el = document.getElementsByClassName('validate-postal-code')[0];
    if (!formValues.externalId && !validatePostalCode(formValues.postalCode)) {
      incomp.push('POSTAL CODE');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }
  }

  if (!isSpecialty) {
    var el = document.getElementsByClassName('checkbox-list-container')[0];
    if (formValues.serviceAreas.length == 0 && !formValues.otherAreas) {
      ret.push('SERVICE AREAS');
      autoOpenSections.push('serviceAreasSection');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }

    const validatePackageFunction = (pkg) => {
      const index = pkg.index;
      var el = document.getElementsByClassName(
        `protection-package_${index}`
      )[0];

      if (pkg.plans.length == 0) {
        if (el) {
          el.classList.add('is-invalid');
          ret.push(`PROTECTION PACKAGE ${getAlphabetLetter(index)}`);
          autoOpenSections.push('protectionDisinfectionSection');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
        if (el) {
          pkg.plans.map((plan, idx) => {
            var planEl = el.getElementsByClassName(
              `protection-package-plan_${index}_${idx}`
            )[0];
            var pEl = planEl.getElementsByClassName(`plan-box`)[0];

            if (!plan.plan) {
              if (pEl) {
                pEl.classList.add('is-invalid');
                ret.push(`PROTECTION PACKAGE ${getAlphabetLetter(index)} PLAN`);
                autoOpenSections.push('protectionDisinfectionSection');
              }
            } else {
              if (pEl && pEl.classList.contains('is-invalid')) {
                pEl.classList.remove('is-invalid');
              }
            }

            var fEl = planEl.getElementsByClassName(`frequency-box`)[0];

            if (!plan.frequency) {
              if (fEl) {
                fEl.classList.add('is-invalid');
                ret.push(
                  `PROTECTION PACKAGE ${getAlphabetLetter(index)} FREQUENCY`
                );
                autoOpenSections.push('protectionDisinfectionSection');
              }
            } else {
              if (fEl && fEl.classList.contains('is-invalid')) {
                fEl.classList.remove('is-invalid');
              }
            }

            if (!pkg.totalOverride) {
              var sEl = planEl.getElementsByClassName(`sqFtApplied-box`)[0];

              if (!plan.sqFtApplied) {
                if (sEl) {
                  sEl.classList.add('is-invalid');
                  ret.push(
                    `PROTECTION PACKAGE ${getAlphabetLetter(
                      index
                    )} SQ. FT. APPLIED`
                  );
                  autoOpenSections.push('protectionDisinfectionSection');
                }
              } else {
                if (sEl && sEl.classList.contains('is-invalid')) {
                  sEl.classList.remove('is-invalid');
                }
              }

              var prEl = planEl.getElementsByClassName(`prodRate-box`)[0];

              if (!plan.prodRate) {
                if (prEl) {
                  prEl.classList.add('is-invalid');
                  ret.push(
                    `PROTECTION PACKAGE ${getAlphabetLetter(index)} PROD RATE`
                  );
                  autoOpenSections.push('protectionDisinfectionSection');
                }
              } else {
                if (prEl && prEl.classList.contains('is-invalid')) {
                  prEl.classList.remove('is-invalid');
                }
              }

              var phEl = planEl.getElementsByClassName(`perHour-box`)[0];

              if (!plan.perHour) {
                if (phEl) {
                  phEl.classList.add('is-invalid');
                  ret.push(
                    `PROTECTION PACKAGE ${getAlphabetLetter(index)} PER HOUR`
                  );
                  autoOpenSections.push('protectionDisinfectionSection');
                }
              } else {
                if (phEl && phEl.classList.contains('is-invalid')) {
                  phEl.classList.remove('is-invalid');
                }
              }
            } else {
              var sEl = planEl.getElementsByClassName(`sqFtApplied-box`)[0];
              var prEl = planEl.getElementsByClassName(`prodRate-box`)[0];
              var phEl = planEl.getElementsByClassName(`perHour-box`)[0];

              if (sEl && sEl.classList.contains('is-invalid')) {
                sEl.classList.remove('is-invalid');
              }
              if (prEl && prEl.classList.contains('is-invalid')) {
                prEl.classList.remove('is-invalid');
              }
              if (phEl && phEl.classList.contains('is-invalid')) {
                phEl.classList.remove('is-invalid');
              }
            }
          });
        }
      }
    };

    const protectionSpecsRoot = document.getElementsByClassName(
      'protection-specification-root'
    )[0];
    if (protectionSpecsRoot) {
      const textAreas = protectionSpecsRoot.getElementsByTagName('textarea');
      for (let ta of textAreas) {
        ta.parentNode.classList.remove('is-invalid');
      }
    }

    if (formValues.protectionPackages.length > 0) {
      formValues.protectionPackages.map(validatePackageFunction);
    }

    if (formValues.secondProtectionPackages.length > 0) {
      formValues.secondProtectionPackages.map(validatePackageFunction);
    }
  }

  if (!isSpecialty) {
    var el = document.getElementsByClassName('validate-frequency')[0];
    if (
      !formValues.frequency ||
      (formValues.frequencyService === 'weekly' &&
        formValues.frequency.selecteds.length == 0) ||
      (formValues.frequencyService === 'monthly' &&
        !el.querySelector('input').value)
    ) {
      ret.push('FREQUENCY');
      if (el) {
        el.classList.add('is-invalid');
      }
    } else {
      if (el && el.classList.contains('is-invalid')) {
        el.classList.remove('is-invalid');
      }
    }

    const hasEmptyProtectionSpecificationValues = Object.keys(
      formValues.protectionSpecification
    )
      .map((key, idx) => ({
        value: key,
        indexes: formValues.protectionSpecification[key].items
          .map((item, idx) => ({
            value: item.title,
            index: idx,
            uid: item.uid,
          }))
          .filter((item) => {
            return !item.value.trim();
          }),
      }))
      .filter(({ indexes }) => {
        return (
          indexes.filter(({ value }) => {
            return !value.trim();
          }).length > 0
        );
      });

    if (
      hasEmptyProtectionSpecificationValues &&
      hasEmptyProtectionSpecificationValues.length > 0
    ) {
      hasEmptyProtectionSpecificationValues.map((item) => {
        ret.push(`PROTECTION SPECIFICATION ${item.value.toUpperCase()}`);
        autoOpenSections.push('protectionDisinfectionSection');
        item.indexes.forEach((i) => {
          invalidSpecs.push(
            `${item.value.toUpperCase()}_${i.index}_item_header_id_${i.uid}`
          );
          invalidSpecs.push(`${item.value}_${i.index}_item_header_id_${i.uid}`);
        });
      });
    }

    const hasSecondFrequency =
      (formValues.frequencyService === 'weekly' &&
        formValues.addFrequency &&
        formValues.secondFrequency &&
        formValues.secondFrequency.selecteds.length > 0) ||
      (formValues.frequencyService === 'monthly' &&
        formValues.monthlyAddFrequency &&
        !!formValues.secondCleaning);

    const validateRoomInventory = (item, index) => {
      var el = document.getElementById(`totalSqft_${index}`);

      if (
        (!item.totalSqft || item.totalSqft == 0) &&
        !!item.roomType &&
        !!item.floorType
      ) {
        ret.push(`${item.roomType.toString().toUpperCase()} TOTAL SQ.FT`);
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    };

    if (formValues.inventory.length > 0) {
      formValues.inventory.map(validateRoomInventory);
    }

    const validateProductionRates = (item, index) => {
      var el = document.getElementById(`rate_${index}`);
      var el2 = document.getElementById(`secondRate_${index}`);

      try {
        if (
          !item.rate &&
          ((!formValues.smartClean && !formValues.bidOverrideBasePrice) ||
            (formValues.smartClean && !formValues.smartCleanOverrideBasePrice))
        ) {
          ret.push('PRODUCTION RATE ' + item.group);
          if (el) {
            el.classList.add('is-invalid');
          }
        }
        // else if((Number(item.rate) >= 0 && Number(item.rate < 100)) && (!formValues.smartClean && !formValues.bidOverrideBasePrice || (formValues.smartClean && !formValues.smartCleanOverrideBasePrice))){
        //   ret.push('PRODUCTION RATE ' + item.group + ' SHOULD BE BIGGER THAN 100');
        //   if (el) {
        //     el.classList.add('is-invalid');
        //   }
        // }
        else {
          if (el && el.classList.contains('is-invalid')) {
            el.classList.remove('is-invalid');
          }
        }
      } catch (e) {
        if (el) {
          el.classList.add('is-invalid');
        }
      }

      try {
        if (
          !item.secondRate &&
          hasSecondFrequency &&
          ((!formValues.smartClean && !formValues.secondBidOverrideBasePrice) ||
            (formValues.smartClean && !formValues.smartCleanOverrideBasePrice))
        ) {
          ret.push('SECOND PRODUCTION RATE ' + item.group);
          if (el2) {
            el2.classList.add('is-invalid');
          }
        }
        // else if((Number(item.secondRate) >= 0 && Number(item.secondRate < 100)) && (!formValues.smartClean && !formValues.secondBidOverrideBasePrice || (formValues.smartClean && !formValues.smartCleanOverrideBasePrice))){
        //   ret.push('SECOND PRODUCTION RATE ' + item.group + ' SHOULD BE BIGGER THAN 100');
        //   if (el2) {
        //     el2.classList.add('is-invalid');
        //   }
        // }
        else {
          if (el2 && el2.classList.contains('is-invalid')) {
            el2.classList.remove('is-invalid');
          }
        }
      } catch (e) {
        if (el2) {
          el2.classList.add('is-invalid');
        }
      }
    };

    /* validation method disabled as fixture fields should not be required */
    const validateRestroomFixtures = (item, index) => {
      var el = document.getElementById(`minutesFixture_${index}`);

      if (
        !item.minutesFixture &&
        (!formValues.bidOverrideBasePrice ||
          (formValues.smartClean && !formValues.smartCleanOverrideBasePrice) ||
          !formValues.secondBidOverrideBasePrice)
      ) {
        ret.push('MINUTES PER FIXTURE ' + item.group);
        if (el) {
          el.classList.add('is-invalid');
        }
      } else {
        if (el && el.classList.contains('is-invalid')) {
          el.classList.remove('is-invalid');
        }
      }
    };

    if (formValues.productionRates.length > 0) {
      formValues.productionRates.map(validateProductionRates);
    }

    /* disables fixture validation method call */
    // if (formValues.restroomFixtures.length > 0) {
    //   formValues.restroomFixtures.map(validateRestroomFixtures);
    // }

    el = document.getElementById(`bidPriceHour`);

    if (
      (!formValues.bidPriceHour || formValues.bidPriceHour == 0) &&
      ((!formValues.smartClean && !formValues.bidOverrideBasePrice) ||
        (formValues.smartClean && !formValues.smartCleanOverrideBasePrice))
    ) {
      ret.push('BID PRICE PER HOUR');
      if (el) {
        el.parentNode.classList.add('is-invalid');
      }
    } else {
      if (el && el.parentNode.classList.contains('is-invalid')) {
        el.parentNode.classList.remove('is-invalid');
      }
    }

    el = document.getElementById(`bidPriceHourSecondFrequency`);

    if (
      (!formValues.bidPriceHourSecondFrequency ||
        formValues.bidPriceHourSecondFrequency == 0) &&
      !formValues.secondBidOverrideBasePrice &&
      hasSecondFrequency &&
      ((!formValues.smartClean && formValues.frequencyService == 'weekly') ||
        formValues.frequencyService == 'monthly')
    ) {
      ret.push('SECOND BID PRICE PER HOUR');
      if (el) {
        el.parentNode.classList.add('is-invalid');
      }
    } else {
      if (el && el.parentNode.classList.contains('is-invalid')) {
        el.parentNode.classList.remove('is-invalid');
      }
    }
  }

  const hasEmptyValues = Object.keys(formValues.cleaningFrequency)
    .filter((key) => {
      return (
        formValues.cleaningFrequency[key].items.filter((item) => {
          return !item?.title?.trim() && !item.mainTitle;
        }).length > 0
      );
    })
    .map((key) => ({
      item: formValues.cleaningFrequency[key].title || key,
      indexes: formValues.cleaningFrequency[key].items
        .map((item, index) => ({ ...item, index }))
        .filter((item) => {
          return !item?.title?.trim() && !item.mainTitle;
        }),
    }));

  const hasEmptySpecsValues = Object.keys(formValues.cleaningFrequency)
    .map((key, idx) => ({
      value: key,
      title: formValues?.cleaningFrequency?.[key]?.title || '',
      index: idx,
    }))
    .filter((obj) => {
      return (
        formValues.cleaningFrequency[obj.value].items.filter((item) => {
          return (
            !item.frequencyTypes ||
            item.frequencyTypes.length === 0 ||
            item.frequencyTypes.filter((i) => i?.value?.trim() !== '').length >
              0
          );
        }).length > 0
      );
    })
    .map((obj) => {
      return {
        ...obj,
        subitems: formValues.cleaningFrequency[obj.value].items
          .map((subitem, idx) => ({
            value: subitem.title,
            index: idx,
            indexes: (subitem.frequencyTypes || [])
              .map((i, idx) => ({ value: i.value, index: idx, uid: i.uid }))
              .filter((i) => !i.value || i?.value?.trim() === ''),
          }))
          .filter((item) => {
            return item.indexes.length > 0;
          }),
      };
    })
    .filter((obj) => {
      return obj.subitems.length > 0;
    });

  const cleaningSpecsRoot = document.getElementsByClassName(
    'cleaning-specification-root'
  )[0];
  if (cleaningSpecsRoot) {
    const textAreas = cleaningSpecsRoot.getElementsByTagName('textarea');
    for (let ta of textAreas) {
      ta.parentNode.classList.remove('is-invalid');
    }
  }

  if (hasEmptyValues && hasEmptyValues.length > 0) {
    hasEmptyValues.map((obj) => {
      ret.push(`${obj.item.toUpperCase()}`);
      if (obj.indexes.length > 0) {
        obj.indexes.map((idx) => {
          invalidSpecs.push(
            `${obj.item.toLowerCase()}_${idx.index}_item_header_id_${idx.uid}`
          );
          invalidSpecs.push(
            `${obj.item}_${idx.index}_item_header_id_${idx.uid}`
          );
          // let el = document.getElementById(`${obj.item.toLowerCase()}_${idx}_item_header_id`);
          // if(el){
          //   // el.parentNode.classList.add('is-invalid')
          //   invalidSpecs.push(el.id)
          // }
        });
      }
    });
  }

  if (hasEmptySpecsValues && hasEmptySpecsValues.length > 0) {
    hasEmptySpecsValues.map((i, keyIndex) => {
      const title = i.title
        ? i.title.toUpperCase()
        : i.value
        ? i.value.toUpperCase()
        : '';
      ret.push(`${title} CLEANING AREA SPECIFICATIONS`);
      i.subitems.map((subitem, subItemIndex) => {
        subitem.indexes.map((obj, idx) => {
          invalidSpecs.push(
            `${i.value.toLowerCase()}_#${subitem.index}#${subitem.value}_${
              subitem.index
            }_subItem_id_${obj.uid}`
          );
          invalidSpecs.push(
            `${i.value}_#${subitem.index}#${subitem.value}_${subitem.index}_subItem_id_${obj.uid}`
          );
          // invalidSpecs.push(`${i.value.toLowerCase()}_${subitem.value}_${subitem.index}_subItem_${obj.index}_id`)
          subListsOpen.push(subitem.value);
          // let el = document.getElementById(`${i.value.toLowerCase()}_${subitem.value}_${subitem.index}_subItem_${obj.index}_id`);
          // if(el){
          //   // el.parentNode.classList.add('is-invalid')
          //   invalidSpecs.push(el.id)
          // }
        });
      });
    });
  }

  setInvalidCleaningSpecs(invalidSpecs, subListsOpen);

  // if (hasEmptySpecsValues && hasEmptySpecsValues.length > 0) {

  //   hasEmptySpecsValues
  //   .filter((i) => !hasEmptyValues.includes(i))
  //   .map((item) => {
  //     ret.push(`${item.value.toUpperCase()} CLEANING AREA SPECIFICATIONS`);
  //   });

  //   hasEmptySpecsValues.map((obj) => {
  //     ret.push(`${obj.item.toUpperCase()}`);
  //     if(obj.indexes.length > 0) {
  //       obj.indexes.map(idx=>{
  //         let el = document.getElementById(`${obj.item.toLowerCase()}_${idx}_item_header_id`);
  //         if(el){
  //           el.parentNode.classList.add('is-invalid')
  //         }
  //       })
  //     }
  //   });
  // }

  const validateDayPorterFields = () => {
    let elDays = document.getElementsByClassName('validate-porterDays')[0];
    let elHourPerDay = document.getElementsByClassName(
      'validate-porterHoursDay'
    )[0];
    let elValueHour = document.getElementsByClassName(
      'validate-porterValueHour'
    )[0];

    elDays.classList.remove('is-invalid');
    elHourPerDay.classList.remove('is-invalid');
    elValueHour.classList.remove('is-invalid');
    clearServiceTitle();

    if (!isNumber(formValues.porterOverride)) {
      if (!formValues.porterHoursDay) {
        ret.push('DAY PORTER HOURS PER DAY');
        autoOpenSections.push('dayPorterSection');
        if (elHourPerDay) {
          elHourPerDay.classList.add('is-invalid');
        }
      } else {
        if (elHourPerDay) {
          elHourPerDay.classList.remove('is-invalid');
        }
      }

      if (!formValues.porterValueHour) {
        ret.push('DAY PORTER HOUR VALUE');
        autoOpenSections.push('dayPorterSection');
        if (elValueHour) {
          elValueHour.classList.add('is-invalid');
        }
      } else {
        if (elValueHour) {
          elValueHour.classList.remove('is-invalid');
        }
      }
    } else {
      if (elValueHour) {
        elValueHour.classList.remove('is-invalid');
      }
      if (elHourPerDay) {
        elHourPerDay.classList.remove('is-invalid');
      }
    }

    if (!formValues.porterDays || formValues.porterDays.length == 0) {
      ret.push('DAY PORTER DAYS');
      autoOpenSections.push('dayPorterSection');
      if (elDays) {
        elDays.classList.add('is-invalid');
      }
    } else {
      if (elDays) {
        elDays.classList.remove('is-invalid');
      }
    }
  };

  if (!isSpecialty) {
    if (
      (formValues.porterDays && formValues.porterDays.length > 0) ||
      formValues.porterHoursDay ||
      formValues.porterValueHour ||
      (isNumber(formValues.porterOverride) &&
        (!formValues.porterDays || formValues.porterDays.length == 0))
    ) {
      validateDayPorterFields();
    } else {
      let elDays = document.getElementsByClassName('validate-porterDays')[0];
      let elHourPerDay = document.getElementsByClassName(
        'validate-porterHoursDay'
      )[0];
      let elValueHour = document.getElementsByClassName(
        'validate-porterValueHour'
      )[0];

      elDays.classList.remove('is-invalid');
      elHourPerDay.classList.remove('is-invalid');
      elValueHour.classList.remove('is-invalid');
      clearServiceTitle();
    }

    let el = document.getElementsByClassName('validate-lengthTerm')[0];

    if (!formValues.lengthTerm) {
      ret.push('LENGTH OF TERMS');
      if (el) {
        el.parentNode.classList.add('is-invalid');
      }
    } else {
      if (el) {
        el.parentNode.classList.remove('is-invalid');
      }
    }

    el = document.getElementsByClassName('validate-cancellationNotice')[0];

    if (!formValues.cancellationNotice) {
      ret.push('CANCELLATION NOTICE');
      if (el) {
        el.parentNode.classList.add('is-invalid');
      }
    } else {
      if (el) {
        el.parentNode.classList.remove('is-invalid');
      }
    }

    el = document.getElementsByClassName('validate-cancellationReason')[0];

    if (!formValues.cancellationReason) {
      ret.push('CANCELLATION REASON');
      if (el) {
        el.parentNode.classList.add('is-invalid');
      }
    } else {
      if (el) {
        el.parentNode.classList.remove('is-invalid');
      }
    }

    el = document.getElementsByClassName('validate-curePeriod')[0];

    if (!formValues.curePeriod) {
      ret.push('CURE PERIOD');
      if (el) {
        el.parentNode.classList.add('is-invalid');
      }
    } else {
      if (el) {
        el.parentNode.classList.remove('is-invalid');
      }
    }
  }

  formValues.quotes.forEach((item, index) => {
    var el = document.getElementsByClassName(
      `validate-pricing-method-${index}`
    )[0];
    if (item.perHour == null && item.quoteSelect != '') {
      ret.push('PRICING METHOD');
      autoOpenSections.push('additionalQuotesSection');
      if (el) {
        el.parentNode.classList.add('is-invalid');
      }
    } else {
      if (el) {
        if (el.parentNode.classList.contains('is-invalid')) {
          el.parentNode.classList.remove('is-invalid');
        }
      }
    }
  });

  if (formValues.quotes.length > 0) {
    const nodeEls = [];

    nodeEls.push(...document.querySelectorAll('.validate-quote-select'));
    nodeEls.push(...document.querySelectorAll('.validate-pricing-method'));
    nodeEls.push(...document.querySelectorAll('.quotePrice'));
    nodeEls.push(...document.querySelectorAll('.unitPrice'));
    nodeEls.push(...document.querySelectorAll('.unitLabel'));
    nodeEls.push(...document.querySelectorAll('.quoteSqft'));

    nodeEls.forEach((e) => {
      if (e.classList.contains('is-invalid')) {
        e.classList.remove('is-invalid');
      }
      if (e.parentNode.classList.contains('is-invalid')) {
        e.parentNode.classList.remove('is-invalid');
      }
    });

    const validateQuote = (item, index) => {
      clearServiceTitle();
      var elQuoteSelect = document.getElementsByClassName(
        `validate-quote-select-${index}`
      )[0];
      var elPricingMethod = document.getElementsByClassName(
        `validate-pricing-method-${index}`
      )[0];

      if (!item.serviceTitle) {
        ret.push(`SERVICE TITLE #${index + 1}`);
        validateServiceTitle();
      }

      if (!item.quoteSelect) {
        ret.push('SPECIALTY SERVICE');
        autoOpenSections.push('additionalQuotesSection');
        if (elQuoteSelect) {
          elQuoteSelect.parentNode.classList.add('is-invalid');
        }
      } else {
        if (elQuoteSelect) {
          if (elQuoteSelect.parentNode.classList.contains('is-invalid')) {
            elQuoteSelect.parentNode.classList.remove('is-invalid');
          }
        }
      }

      if (
        item.perHour == null &&
        item.perMonth == null &&
        item.uponRequest == false &&
        item.unit == false
      ) {
        ret.push('PRICING METHOD');
        autoOpenSections.push('additionalQuotesSection');
        if (elPricingMethod) {
          elPricingMethod.parentNode.classList.add('is-invalid');
        }
      } else {
        if (elPricingMethod) {
          if (elPricingMethod.parentNode.classList.contains('is-invalid')) {
            elPricingMethod.parentNode.classList.remove('is-invalid');
          }
        }
      }

      var el, el2;
      el = document.getElementById(`quotePrice_${index}`);

      if (item.perHour) {
        // if (!item.serviceTitle) {
        //   ret.push('SERVICE TITLE');
        //   validateServiceTitle();
        // }

        if (!item.quotePrice) {
          ret.push('SERVICE PRICE');
          autoOpenSections.push('additionalQuotesSection');
          if (el) {
            el.parentNode.classList.add('is-invalid');
          }
        } else {
          if (el) {
            if (el.parentNode.classList.contains('is-invalid')) {
              el.parentNode.classList.remove('is-invalid');
            }
          }
        }
      } else if (item.perMonth) {
        // if (!item.serviceTitle) {
        //   ret.push('SERVICE TITLE');
        //   validateServiceTitle();
        // }

        if (!item.quotePrice) {
          ret.push('SERVICE PRICE');
          autoOpenSections.push('additionalQuotesSection');
          if (el) {
            el.parentNode.classList.add('is-invalid');
          }
        } else {
          if (el) {
            if (el.parentNode.classList.contains('is-invalid')) {
              el.parentNode.classList.remove('is-invalid');
            }
          }
        }
      } else if (item.unit) {
        // if (!item.serviceTitle) {
        //   ret.push('SERVICE TITLE');
        //   validateServiceTitle();
        // }

        el = document.getElementById(`unitPrice_${index}`);
        el2 = document.getElementById(`unitLabel_${index}`);

        if (!item.unitPrice) {
          ret.push('UNIT PRICE');
          autoOpenSections.push('additionalQuotesSection');
          if (el) {
            el.parentNode.classList.add('is-invalid');
          }
        } else {
          if (el) {
            if (el.parentNode.classList.contains('is-invalid')) {
              el.parentNode.classList.remove('is-invalid');
            }
          }
        }

        if (!item.unitLabel) {
          ret.push('UNIT LABEL');
          autoOpenSections.push('additionalQuotesSection');
          if (el2) {
            el2.parentNode.classList.add('is-invalid');
          }
        } else {
          if (el2) {
            if (el2.parentNode.classList.contains('is-invalid')) {
              el2.parentNode.classList.remove('is-invalid');
            }
          }
        }
      } else if (!item.uponRequest) {
        el = document.getElementById(`quoteSqft_${index}`);
        el2 = document.getElementsByClassName(
          `quote-applied-content_${index}`
        )[0];

        if (!item.quoteSqft && !item.quoteOverride) {
          ret.push('SERVICE $ / SQ. FT.');
          autoOpenSections.push('additionalQuotesSection');
          if (el) {
            el.parentNode.classList.add('is-invalid');
          }
        } else {
          if (el) {
            if (el.parentNode.classList.contains('is-invalid')) {
              el.parentNode.classList.remove('is-invalid');
            }
          }
        }

        // if (!item.serviceTitle) {
        //   ret.push('SERVICE TITLE');
        //   validateServiceTitle();
        // }

        if (
          (!item.sqftApplied && !item.quoteOverride) ||
          (!item.quoteOverride && item.sqftApplied === '')
        ) {
          ret.push('SERVICE SQ. FT. APPLIED');
          autoOpenSections.push('additionalQuotesSection');
          if (el2) {
            el2.classList.add('is-invalid');
          }
        } else if (!!item.sqftApplied && isNaN(item.sqftApplied)) {
          incomp.push('SERVICE SQ. FT. APPLIED');
          autoOpenSections.push('additionalQuotesSection');
          if (el2) {
            el2.classList.add('is-invalid');
          }
        } else {
          if (el2) {
            if (el2.classList.contains('is-invalid')) {
              el2.classList.remove('is-invalid');
            }
          }
        }
      }
    };

    formValues.quotes.forEach((item, index) => {
      validateQuote(item, index);
    });

    if (formValues.quotes.length === 0 && isSpecialty) {
      validateQuote({}, 0);
    }
  }

  const invalids = document.getElementsByClassName('has-error');

  if (invalids && invalids.length > 0) {
    for (const field of invalids) {
      incomp.push(field.getAttribute('data-label'));
      field.classList.add('is-invalid');
      if (field.getAttribute('data-section-id')) {
        autoOpenSections.push(field.getAttribute('data-section-id'));
      }
    }
  }

  if (formValues.calculatedPrice > 999999) {
    incomp.push(
      'First quote price is bigger than allowed. Please review your numbers.'
    );
  }
  if (formValues.secondCalculatedPrice > 999999) {
    incomp.push(
      'Second quote price is bigger than allowed. Please review your numbers.'
    );
  }
  if (formValues.overridePrice > 999999) {
    incomp.push(
      'First quote override is bigger than allowed. Please review your numbers.'
    );
  }
  if (formValues.secondOverride > 999999) {
    incomp.push(
      'Second quote override is bigger than allowed. Please review your numbers.'
    );
  }
  if (formValues.calculatedSmartCleanPrice > 999999) {
    incomp.push(
      'SmartClean quote is bigger than allowed. Please review your numbers.'
    );
  }
  if (formValues.smartCleanOverridePrice > 999999) {
    incomp.push(
      'SmartClean quote override is bigger than allowed. Please review your numbers.'
    );
  }

  EventEmitter.emit('AUTO_OPEN_ON_VALIDATE', autoOpenSections);

  if (ret.length > 0) {
    window.scrollTo(0, 0);
  }
  return { required: ret, incorrect: incomp };
};

export const validateSaveDraft = (
  formValues: any,
  isNewTemplate: boolean = false
) => {
  const ret = [];

  if (!formValues.pid && !isNewTemplate) {
    ret.push('PID');
  }
  var el = document.getElementsByClassName('validate-pid')[0];

  if (
    (!formValues.referenceName || !formValues.referenceName.trim()) &&
    isNewTemplate
  ) {
    ret.push('Template Name');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  return { required: ret, incorrect: [] };
};

export const validateSaveTemplate = (formValues: any) => {
  const ret = [];

  var el = document.getElementsByClassName('validate-pid')[0];

  if (
    !formValues.referenceName?.trim() &&
    !formValues.newReferenceName?.trim()
  ) {
    ret.push('Template Name');
    if (el) {
      el.classList.add('is-invalid');
    }
  } else {
    if (el && el.classList.contains('is-invalid')) {
      el.classList.remove('is-invalid');
    }
  }

  return { required: ret, incorrect: [] };
};
