import React, { memo, useEffect } from 'react';
import { withFormik, FormikProps } from 'formik';
import { IBidInformation } from 'modules/BidInfoForm/interfaces';
import { bidInformationValues } from 'modules/shared/bidDefaultValues';

import { Layout } from 'modules/BidInfoForm/components/layout';

import {
  ContentInput,
  BoxInput,
  BoxInputWithValidation,
  useFormProps,
  Tooltip,
  Select,
  DatePicker,
  InputFloatLabel,
  DisabledBoxValue as BoxValue,
  GenericAccordion,
  Checkbox,
} from 'core/components';

import { AccordionHeader } from 'modules/BidInfoForm/styles';

import {
  formatPrice,
  getAlphabetLetter,
  isIpad,
  convertStringToNumber,
  moneyMask,
  moneyMaskParams,
} from 'core/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const isValueValid = (value) => {
  const rValue = convertStringToNumber(value);
  return rValue != undefined && rValue != null && rValue != '' && rValue >= 0;
};

type IProps = FormikProps<IBidInformation>;
const BidInformation = memo(({ isTemplateScreen = false }: IProps) => {
  const { onChange, formValues, setFormValues } = useFormProps();

  const { smartClean, userHasBetaOptIn, expirationDays, expirationDate } =
    formValues;

  const [selectedCustom, setSelectedCustom] = React.useState(false);

  useEffect(() => {
    if (
      !!expirationDays &&
      expirationDays != 30 &&
      expirationDays != 45 &&
      expirationDays != 60 &&
      expirationDays != 90 &&
      expirationDays != 180
    ) {
      setSelectedCustom(true);
    }
  }, [expirationDays]);

  useEffect(() => {
    const datePickers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );
    Array.from(datePickers).forEach((el) =>
      el.childNodes[0].setAttribute('readOnly', true)
    );
  }, []);

  const [biggerThan768, setBiggerThan768] = React.useState(false);

  const setBreakpoints = () => {
    setBiggerThan768(window.innerWidth >= 769);
  };

  React.useEffect(() => {
    setBreakpoints();
  }, [window.innerWidth]);

  React.useLayoutEffect(() => {
    window.addEventListener('resize', setBreakpoints);
    setBreakpoints();
    return () => window.removeEventListener('resize', setBreakpoints);
  }, []);

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const onChangeProtectionPackages =
    (packageName) =>
    (packageIndex) =>
    (propertyName) =>
    (index, name, value) => {
      const pkg = [...formValues[packageName]];
      if (index != -1) {
        pkg[packageIndex][propertyName][index][name] = value;
      } else {
        pkg[packageIndex][propertyName] = value;
      }

      onChange(packageName, [...pkg]);
    };

  const containsPreviousDays = (date: Date) => {
    return date >= new Date();
  };

  const onChangeExpirationDatePicker = (date: Date) => {
    const expirationDaysCount = new Date(date);
    expirationDaysCount.setHours(12);

    const now = new Date();
    now.setHours(12);
    now.setMinutes(0);
    now.setSeconds(0);

    const timeDiff = Math.abs(expirationDaysCount.getTime() - now.getTime());
    const diffDays = Math.round(timeDiff / (1000 * 3600 * 24));

    onChange('expirationDays', diffDays);
    onChange('expirationDate', new Date(date).toISOString());
  };

  const handleChangeExpirationDays = (expirationDays) => {
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + expirationDays);
    onChange('expirationDays', expirationDays);
    onChange('expirationDate', expiration.toISOString());
  };

  const datesAvailable = [
    { value: 30, label: '30 Days' },
    { value: 45, label: '45 Days' },
    { value: 60, label: '60 Days' },
    { value: 90, label: '90 Days' },
    { value: 180, label: '180 Days' },
  ];

  if (selectedCustom) {
    const customDate = {
      value: expirationDays,
      label: `${expirationDays} Days`,
    };
    if (datesAvailable.findIndex((i) => i.value == expirationDays) == -1) {
      datesAvailable.unshift(customDate);
    }
  }

  const ServicesHeader = () => (
    <ContentInput
      width="100%"
      justify="space-between"
      flexDirection="row"
      paddingTop="0px"
    >
      <span style={{ textAlign: 'center', width: '50%' }}>Service</span>
      <span style={{ textAlign: 'center', width: '25%' }}>Calc</span>
      <span style={{ textAlign: 'center', width: '25%' }}>Override</span>
    </ContentInput>
  );

  const hasSecondFrequency =
    (formValues.frequencyService === 'weekly' &&
      formValues.addFrequency &&
      formValues.secondFrequency &&
      formValues.secondFrequency.selecteds.length > 0) ||
    (formValues.frequencyService === 'monthly' &&
      formValues.monthlyAddFrequency &&
      !!formValues.secondCleaning);

  const boxInputStyles = {
    maxWidth: '15%',
    border: '1px solid gray',
    borderLeft: 'none',
    height: '46px',
    textAlign: 'right',
    paddingRight: '10px',
    fontSize: '12px',
    borderRadius: '0px',
    boxShadow: 'none',
    appearance: 'none',
    outline: 'none',
  };

  const boxInputWithValidationStyles = {
    ...boxInputStyles,
  };

  const isSmall = window.innerWidth < 900;

  return (
    <div className="bid-information-root">
      <div className="bid-information-content">
        <ContentInput justify="flex-start">
          <h3 className="bid-information-title">Bid Information</h3>
          {userHasBetaOptIn && (
            <Tooltip
              overlay={
                <span
                  style={{
                    fontSize: '13px',
                    display: 'block',
                    textAlign: 'justify',
                  }}
                >
                  $5 will automatically be added to any Calculated or Override
                  Bid Price. The client can have this discounted back by
                  selecting Eco-Invoice (ACH Debit) as their payment method.
                </span>
              }
              trigger="click"
              placement="top"
              alignedItemId="betaOptInInfoIcon"
            >
              <div className="beta-optin-button-tooltip" id="betaOptInInfoIcon">
                <FontAwesomeIcon icon={faInfo} size="xs" color="#fff" />
              </div>
            </Tooltip>
          )}
        </ContentInput>

        {smartClean && formValues.frequencyService === 'weekly' ? (
          <div className="information-row">
            <ContentInput width="48%">
              <InputFloatLabel
                disabled={formValues.status === 2}
                value={formValues.quoteTitle}
                label={'SmartClean Title'}
                name={'quoteTitle'}
                onChange={onChange}
              />
            </ContentInput>
          </div>
        ) : (
          <div className="information-row">
            <ContentInput width="48%">
              <InputFloatLabel
                disabled={formValues.status === 2}
                value={formValues.quoteTitle}
                label={'Quote 1 Title'}
                name={'quoteTitle'}
                onChange={onChange}
              />
            </ContentInput>
            {hasSecondFrequency && (
              <ContentInput width="48%">
                <InputFloatLabel
                  disabled={formValues.status === 2}
                  value={formValues.secondQuoteTitle}
                  label={'Quote 2 Title'}
                  name={'secondQuoteTitle'}
                  onChange={onChange}
                />
              </ContentInput>
            )}
          </div>
        )}

        {smartClean && formValues.frequencyService === 'weekly' ? (
          <div className="information-row">
            <ContentInput width="48%" flex flexDirection="row" maxWidth="60%">
              <BoxValue
                maxWidth="100%"
                minWidth="100%"
                contentStyle={{ height: '44px' }}
                labelWidth="70%"
                labelStyle={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                }}
                renderLabel={() => (
                  <>
                    <span>
                      Frequency:{' '}
                      {formValues.frequency.selecteds.length +
                        (formValues.secondFrequency
                          ? formValues.secondFrequency.selecteds.length
                          : 0)}
                      x Weekly SmartClean
                    </span>
                    <span>Total:</span>
                  </>
                )}
                value={formatPrice(
                  formValues.calculatedSmartCleanPrice || 0,
                  userHasBetaOptIn
                )}
              />
            </ContentInput>
          </div>
        ) : (
          <div className="information-row">
            <ContentInput width="48%" flex flexDirection="row" maxWidth="48%">
              <BoxValue
                maxWidth="100%"
                minWidth="100%"
                contentStyle={{ height: '44px' }}
                labelWidth="70%"
                labelStyle={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                }}
                renderLabel={() => (
                  <>
                    <span>
                      Frequency:{' '}
                      {formValues.frequencyService === 'weekly'
                        ? `${formValues.frequency.selecteds.length}x Weekly`
                        : `${formValues.cleaning}x Monthly`}
                    </span>
                    <span>Total:</span>
                  </>
                )}
                value={formatPrice(
                  formValues.calculatedPrice || 0,
                  userHasBetaOptIn
                )}
              />
            </ContentInput>
            {hasSecondFrequency && (
              <ContentInput width="48%" flex flexDirection="row" maxWidth="48%">
                <BoxValue
                  maxWidth="100%"
                  minWidth="100%"
                  contentStyle={{ height: '44px' }}
                  labelWidth="70%"
                  labelStyle={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 'bold',
                  }}
                  renderLabel={() => (
                    <>
                      <span>
                        Frequency:{' '}
                        {formValues.frequencyService === 'weekly'
                          ? `${
                              (formValues.secondFrequency &&
                                formValues.secondFrequency.selecteds.length) ||
                              0
                            }x Weekly`
                          : `${formValues.secondCleaning}x Monthly`}
                      </span>
                      <span>Total:</span>
                    </>
                  )}
                  value={formatPrice(
                    formValues.secondCalculatedPrice || 0,
                    userHasBetaOptIn
                  )}
                />
              </ContentInput>
            )}
          </div>
        )}

        <div className="information-row">
          <GenericAccordion
            headerStyle={{
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '10px',
            }}
            containerStyle={{
              marginTop: '15px',
              borderRadius: '10px',
              border: 'none',
            }}
            contentStyle={{
              marginTop: '-10px',
              paddingBottom: '10px',
              borderBottomRightRadius: '10px',
              borderBottomLeftRadius: '10px',
              marginLeft: isIpad() ? '2px' : 0,
              width: isIpad() ? 'calc(100% - 4px)' : '100%',
            }}
            renderHeader={() => (
              <AccordionHeader
                maxFontSize="18px"
                width="100%"
                textAlign={'center'}
                textTransform="capitalize"
                color="darkslategray"
              >
                Itemized List of Included Service
              </AccordionHeader>
            )}
          >
            <Layout padding="20px 10px 20px 10px">
              {smartClean && formValues.frequencyService === 'weekly' ? (
                <ContentInput
                  width="100%"
                  flex
                  flexDirection="row"
                  justify="space-between"
                  paddingTop="0px"
                >
                  <ContentInput
                    width="49.5%"
                    justify="flex-start"
                    flexDirection="column"
                    maxWidth="49.5%"
                    paddingTop="0px"
                  >
                    <ServicesHeader />
                    <ContentInput
                      width="100%"
                      justify="flex-start"
                      flexDirection="row"
                      paddingTop="0px"
                      margin="-1px 0px 0px 0px"
                    >
                      <BoxValue
                        minWidth="85%"
                        labelWidth="65%"
                        labelStyle={{ fontSize: '12px' }}
                        contentStyle={{ height: '44px' }}
                        valueStyle={{ textAlign: 'right', fontSize: '12px' }}
                        label="Customized Cleaning Quote"
                        value={formatPrice(
                          formValues.smartCleanCalculatedBasePrice || 0,
                          false
                        )}
                      />

                      <BoxInputWithValidation
                        mask={moneyMask}
                        maskParams={{
                          ...moneyMaskParams,
                          thousandsSeparatorSymbol: true,
                        }}
                        onBlur={(name, value) => {
                          onChange(name, value !== '' ? value : undefined);
                        }}
                        inputStyle={boxInputWithValidationStyles}
                        disabled={formValues.status === 2}
                        value={formValues.smartCleanOverrideBasePrice}
                        minWidth="25%"
                        name="smartCleanOverrideBasePrice"
                        onChange={onChange}
                        flex
                        flexDirection="flex-end"
                      />
                    </ContentInput>

                    {isValueValid(formValues.costSupplies) && (
                      <ContentInput
                        width="100%"
                        justify="flex-start"
                        flexDirection="row"
                        paddingTop="0px"
                        margin="-1px 0px 0px 0px"
                      >
                        <BoxValue
                          minWidth="85%"
                          labelWidth="65%"
                          contentStyle={{ height: '44px' }}
                          label="Supplies Included"
                          value={formatPrice(formValues.costSupplies, false)}
                          labelStyle={{ fontSize: '12px' }}
                          valueStyle={{
                            textAlign: 'right',
                            fontSize: '12px',
                          }}
                        />

                        <BoxInput
                          value="N/A"
                          tabIndex="-1"
                          inputStyle={{
                            ...boxInputStyles,
                            backgroundColor: '#ededed',
                            color: '#515151',
                            width: '60px',
                            minWidth: '25%',
                            pointerEvents: 'none',
                          }}
                          name="overrideCostSupplies"
                          flex
                          flexDirection="flex-end"
                        />
                      </ContentInput>
                    )}

                    {formValues.includeInPrice &&
                      (isValueValid(formValues.porterOverride) ||
                        isValueValid(formValues.monthlyPorterPrice)) && (
                        <ContentInput
                          width="100%"
                          flex
                          flexDirection="row"
                          paddingTop="0px"
                          margin="-1px 0px 0px 0px"
                        >
                          <BoxValue
                            minWidth="85%"
                            labelWidth="65%"
                            contentStyle={{ height: '44px' }}
                            label="Day Porter Included"
                            value={formatPrice(
                              formValues.monthlyPorterPrice,
                              false
                            )}
                            labelStyle={{ fontSize: '12px' }}
                            valueStyle={{
                              textAlign: 'right',
                              fontSize: '12px',
                            }}
                          />

                          <BoxInputWithValidation
                            mask={moneyMask}
                            maskParams={{
                              ...moneyMaskParams,
                              thousandsSeparatorSymbol: true,
                            }}
                            onBlur={(name, value) => {
                              onChange(name, value !== '' ? value : undefined);
                            }}
                            inputStyle={boxInputWithValidationStyles}
                            disabled={formValues.status === 2}
                            value={formValues.porterOverride}
                            minWidth="25%"
                            name="porterOverride"
                            onChange={onChange}
                            flex
                            flexDirection="flex-end"
                          />
                        </ContentInput>
                      )}
                  </ContentInput>
                </ContentInput>
              ) : (
                <ContentInput
                  width="100%"
                  flex
                  flexDirection="row"
                  justify="space-between"
                  paddingTop="0px"
                >
                  <ContentInput
                    width="49.5%"
                    justify="flex-start"
                    flexDirection="column"
                    maxWidth="49.5%"
                    paddingTop="0px"
                  >
                    <ServicesHeader />
                    <ContentInput
                      width="100%"
                      justify="flex-start"
                      flexDirection="row"
                      paddingTop="0px"
                      margin="-1px 0px 0px 0px"
                    >
                      <BoxValue
                        minWidth="85%"
                        labelWidth="65%"
                        contentStyle={{ height: '44px' }}
                        labelStyle={{ fontSize: '12px' }}
                        valueStyle={{ textAlign: 'right', fontSize: '12px' }}
                        label="Customized Cleaning Quote"
                        value={formatPrice(
                          formValues.bidCalculatedBasePrice || 0,
                          false
                        )}
                      />

                      <BoxInputWithValidation
                        mask={moneyMask}
                        maskParams={{
                          ...moneyMaskParams,
                          thousandsSeparatorSymbol: true,
                        }}
                        onBlur={(name, value) => {
                          onChange(name, value);
                        }}
                        inputStyle={boxInputWithValidationStyles}
                        disabled={formValues.status === 2}
                        value={formValues.bidOverrideBasePrice}
                        minWidth="25%"
                        name="bidOverrideBasePrice"
                        onChange={onChange}
                        flex
                        flexDirection="flex-end"
                      />
                    </ContentInput>

                    {isValueValid(formValues.costSupplies) && (
                      <ContentInput
                        width="100%"
                        justify="flex-start"
                        flexDirection="row"
                        paddingTop="0px"
                        margin="-1px 0px 0px 0px"
                      >
                        <BoxValue
                          minWidth="85%"
                          labelWidth="65%"
                          contentStyle={{ height: '44px' }}
                          label="Supplies Included"
                          value={formatPrice(formValues.costSupplies, false)}
                          labelStyle={{ fontSize: '12px' }}
                          valueStyle={{
                            textAlign: 'right',
                            fontSize: '12px',
                          }}
                        />

                        <BoxInput
                          value="N/A"
                          tabIndex="-1"
                          inputStyle={{
                            ...boxInputStyles,
                            backgroundColor: '#ededed',
                            color: '#515151',
                            width: '60px',
                            minWidth: '25%',
                            pointerEvents: 'none',
                          }}
                          name="overrideCostSupplies"
                          flex
                          flexDirection="flex-end"
                        />
                      </ContentInput>
                    )}

                    {formValues.includeInPrice &&
                      (isValueValid(formValues.porterOverride) ||
                        isValueValid(formValues.monthlyPorterPrice)) && (
                        <ContentInput
                          width="100%"
                          flex
                          flexDirection="row"
                          paddingTop="0px"
                          margin="-1px 0px 0px 0px"
                        >
                          <BoxValue
                            minWidth="85%"
                            labelWidth="65%"
                            contentStyle={{ height: '44px' }}
                            label="Day Porter Included"
                            value={formatPrice(
                              formValues.monthlyPorterPrice,
                              false
                            )}
                            labelStyle={{ fontSize: '12px' }}
                            valueStyle={{
                              textAlign: 'right',
                              fontSize: '12px',
                            }}
                          />

                          <BoxInputWithValidation
                            mask={moneyMask}
                            maskParams={{
                              ...moneyMaskParams,
                              thousandsSeparatorSymbol: true,
                            }}
                            onBlur={(name, value) => {
                              onChange(name, value);
                            }}
                            inputStyle={boxInputWithValidationStyles}
                            disabled={formValues.status === 2}
                            value={formValues.porterOverride}
                            minWidth="25%"
                            name="porterOverride"
                            onChange={onChange}
                            flex
                            flexDirection="flex-end"
                          />
                        </ContentInput>
                      )}

                    {formValues.protectionPackages &&
                      formValues.protectionIncludedInPrice &&
                      formValues.protectionPackages.map((item, index) => (
                        <ContentInput
                          width="100%"
                          justify="flex-start"
                          flexDirection="row"
                          paddingTop="0px"
                          margin="-1px 0px 0px 0px"
                        >
                          <BoxValue
                            minWidth="85%"
                            labelWidth="65%"
                            contentStyle={{ height: '44px' }}
                            label={`Protection + Package ${getAlphabetLetter(
                              item.index
                            )} Included`}
                            value={formatPrice(item.total, false)}
                            labelStyle={{ fontSize: '12px' }}
                            valueStyle={{
                              textAlign: 'right',
                              fontSize: '12px',
                            }}
                          />

                          <BoxInputWithValidation
                            mask={moneyMask}
                            maskParams={{
                              ...moneyMaskParams,
                              thousandsSeparatorSymbol: true,
                            }}
                            onBlur={(name, value) => {
                              onChangeProtectionPackages('protectionPackages')(
                                index
                              )('totalOverride')(-1, undefined, value);
                            }}
                            inputStyle={boxInputWithValidationStyles}
                            disabled={formValues.status === 2}
                            value={item.totalOverride}
                            minWidth="25%"
                            name={`package_${item.index}`}
                            onChange={(name, value) =>
                              onChangeProtectionPackages('protectionPackages')(
                                index
                              )('totalOverride')(-1, undefined, value)
                            }
                            flex
                            flexDirection="flex-end"
                          />
                        </ContentInput>
                      ))}
                  </ContentInput>
                  {hasSecondFrequency && (
                    <ContentInput
                      width="49.5%"
                      justify="flex-start"
                      flexDirection="column"
                      maxWidth="49.5%"
                      paddingTop="0px"
                    >
                      <ServicesHeader />
                      <ContentInput
                        width="100%"
                        justify="flex-start"
                        flexDirection="row"
                        paddingTop="0px"
                        margin="-1px 0px 0px 0px"
                      >
                        <BoxValue
                          minWidth="85%"
                          labelWidth="65%"
                          contentStyle={{ height: '44px' }}
                          label="Customized Cleaning Quote"
                          value={formatPrice(
                            formValues.secondBidCalculatedBasePrice || 0,
                            false
                          )}
                          labelStyle={{ fontSize: '12px' }}
                          valueStyle={{ textAlign: 'right', fontSize: '12px' }}
                        />

                        <BoxInputWithValidation
                          mask={moneyMask}
                          maskParams={{
                            ...moneyMaskParams,
                            thousandsSeparatorSymbol: true,
                          }}
                          onBlur={(name, value) => {
                            onChange(name, value);
                          }}
                          inputStyle={boxInputWithValidationStyles}
                          disabled={formValues.status === 2}
                          value={formValues.secondBidOverrideBasePrice}
                          minWidth="25%"
                          name="secondBidOverrideBasePrice"
                          onChange={(name, value) => {
                            onChange(name, value);
                          }}
                          flex
                          flexDirection="flex-end"
                        />
                      </ContentInput>

                      {isValueValid(formValues.costSupplies) && (
                        <ContentInput
                          width="100%"
                          justify="flex-start"
                          flexDirection="row"
                          paddingTop="0px"
                          margin="-1px 0px 0px 0px"
                        >
                          <BoxValue
                            minWidth="85%"
                            labelWidth="65%"
                            contentStyle={{ height: '44px' }}
                            label="Supplies Included"
                            value={formatPrice(formValues.costSupplies, false)}
                            labelStyle={{ fontSize: '12px' }}
                            valueStyle={{
                              textAlign: 'right',
                              fontSize: '12px',
                            }}
                          />

                          <BoxInput
                            value="N/A"
                            tabIndex="-1"
                            inputStyle={{
                              ...boxInputStyles,
                              backgroundColor: '#ededed',
                              color: '#515151',
                              width: '60px',
                              minWidth: '25%',
                              pointerEvents: 'none',
                            }}
                            name="overrideCostSupplies"
                            flex
                            flexDirection="flex-end"
                          />
                        </ContentInput>
                      )}

                      {formValues.includeInPrice &&
                        (isValueValid(formValues.porterOverride) ||
                          isValueValid(formValues.monthlyPorterPrice)) && (
                          <ContentInput
                            width="100%"
                            justify="flex-start"
                            flexDirection="row"
                            paddingTop="0px"
                            margin="-1px 0px 0px 0px"
                          >
                            <BoxValue
                              minWidth="85%"
                              labelWidth="65%"
                              contentStyle={{ height: '44px' }}
                              label="Day Porter Included"
                              value={formatPrice(
                                formValues.monthlyPorterPrice,
                                false
                              )}
                              labelStyle={{ fontSize: '12px' }}
                              valueStyle={{
                                textAlign: 'right',
                                fontSize: '12px',
                              }}
                            />

                            <BoxInputWithValidation
                              mask={moneyMask}
                              maskParams={{
                                ...moneyMaskParams,
                                thousandsSeparatorSymbol: true,
                              }}
                              onBlur={(name, value) => {
                                onChange(name, value);
                              }}
                              inputStyle={boxInputWithValidationStyles}
                              disabled={formValues.status === 2}
                              value={formValues.porterOverride}
                              minWidth="25%"
                              name="porterOverride"
                              onChange={onChange}
                              flex
                              flexDirection="flex-end"
                            />
                          </ContentInput>
                        )}

                      {formValues.secondProtectionPackages &&
                        formValues.secondProtectionIncludedInPrice &&
                        formValues.secondProtectionPackages.map(
                          (item, index) => (
                            <ContentInput
                              width="100%"
                              justify="flex-start"
                              flexDirection="row"
                              paddingTop="0px"
                              margin="-1px 0px 0px 0px"
                            >
                              <BoxValue
                                minWidth="85%"
                                labelWidth="65%"
                                contentStyle={{ height: '44px' }}
                                label={`Protection + Package ${getAlphabetLetter(
                                  item.index
                                )} Included`}
                                value={formatPrice(item.total, false)}
                                labelStyle={{ fontSize: '12px' }}
                                valueStyle={{
                                  textAlign: 'right',
                                  fontSize: '12px',
                                }}
                              />

                              <BoxInputWithValidation
                                mask={moneyMask}
                                maskParams={{
                                  ...moneyMaskParams,
                                  thousandsSeparatorSymbol: true,
                                }}
                                onBlur={(name, value) => {
                                  onChangeProtectionPackages(
                                    'secondProtectionPackages'
                                  )(index)('totalOverride')(
                                    -1,
                                    undefined,
                                    value
                                  );
                                }}
                                inputStyle={boxInputWithValidationStyles}
                                disabled={formValues.status === 2}
                                value={item.totalOverride}
                                minWidth="25%"
                                name={`package_${item.index}`}
                                onChange={(name, value) =>
                                  onChangeProtectionPackages(
                                    'secondProtectionPackages'
                                  )(index)('totalOverride')(
                                    -1,
                                    undefined,
                                    value
                                  )
                                }
                                flex
                                flexDirection="flex-end"
                              />
                            </ContentInput>
                          )
                        )}
                    </ContentInput>
                  )}
                </ContentInput>
              )}
            </Layout>
          </GenericAccordion>
        </div>
        {!isTemplateScreen ? (
          <div className="information-row">
            <ContentInput
              width="100%"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ width: '50%' }}></div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <h3
                  className="bid-information-title"
                  style={{
                    paddingTop: '15px',
                    paddingRight: '15px',
                    textWrap: 'nowrap',
                    fontSize: isSmall ? '16px' : '18px',
                  }}
                >
                  Expiration Date
                </h3>

                <ContentInput
                  width="100%"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Select
                    style={{ marginLeft: '30px' }}
                    disabled={formValues.status === 2}
                    value={formValues.expirationDays}
                    items={datesAvailable}
                    name="expirationDays"
                    onChange={(name, item) =>
                      handleChangeExpirationDays(item.value)
                    }
                    width="48%"
                    getLabelFunction={(item) => item.label}
                    getValueFunction={(item) => item.value}
                    canTypeCustomValue={false}
                    blockCustomInput={true}
                  />

                  <div className="button-date-picker">
                    <DatePicker
                      onChange={(date) => onChangeExpirationDatePicker(date)}
                      onBlur={() => {}}
                      className={`input-header input-bid expiration-date`}
                      filterDate={containsPreviousDays}
                      selected={new Date(expirationDate)}
                      value
                      disabled={formValues.status === 2}
                      popperPlacement="bottom-end"
                    />
                  </div>
                </ContentInput>
              </div>
            </ContentInput>
          </div>
        ) : null}
      </div>
    </div>
  );
});

const mapForm = {
  mapPropsToformValues: () => ({
    ...bidInformationValues,
  }),
  handleSubmit: () => '',
};

const EnhancedBidInformation = withFormik<any, IBidInformation>(mapForm)(
  BidInformation
);

export { EnhancedBidInformation as BidInformation };
