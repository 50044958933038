import React, { useState, useEffect, useLayoutEffect } from 'react';

import { Content, Input, DataContent, ItemContent } from './styles';

import { useMediaQuery } from 'react-responsive';

interface IAutoCompleteInput {
  value: string;
  data: Array<string>;
  onChange: Function;
  name?: string;
  id?: string;
  onBlur?: any;
  bold?: boolean;
  disabled?: boolean;
  inputRef?: any;
  titleStyles?: any;
  autoCapitalize?: string;
  dataCy?: string;
  textTransform?: string;
  itemsCount?: number;
  orderChange?: boolean;
  onFocus?: Function;
}

export const AutoCompleteInput = ({
  value: inputValue,
  name,
  data,
  id,
  onChange: providerOnChange,
  onBlur,
  bold,
  disabled = false,
  inputRef,
  titleStyles,
  autoCapitalize = 'words',
  dataCy,
  textTransform,
  itemsCount,
  orderChange,
  onFocus,
}: IAutoCompleteInput) => {
  const [focused, setFocused] = useState(false);
  const [resultSearched, setResultSearched] = useState([]);
  const [value, setValue] = useState(inputValue);

  const biggerThan768 = window.innerWidth >= 769;

  const biggerThan980 = useMediaQuery({
    query: '(min-device-width: 981px)',
  });

  const biggerThan1240 = useMediaQuery({
    query: '(min-device-width: 1241px)',
  });

  const onChange = (name, value, event) => {
    setValue(value);
    providerOnChange(name, value, event);
  };

  useEffect(() => {
    if (inputValue != value) {
      setValue(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (focused) {
      setResultSearched(
        data.filter((item: any) => {
          return (
            value &&
            item.value &&
            item.value.toLowerCase().includes(value.toLowerCase())
          );
        })
      );
    }
    // eslint-disable-next-line
  }, [focused]);

  useEffect(() => {
    if (focused) {
      setResultSearched(
        data.filter((item: any) => {
          return (
            value &&
            item.value &&
            item.value.toLowerCase().includes(value.toLowerCase())
          );
        })
      );
    }
    // eslint-disable-next-line
  }, [value]);

  const [cursor, setCursor] = useState(0);

  const rowsConst = biggerThan1240
    ? 100
    : biggerThan980
    ? 84
    : biggerThan768
    ? 70
    : 55;

  const adjustHeight = (e) => {
    const el = e.currentTarget || e.target;
    if (el) {
      el.style.height = 'fit-content';
      el.style.height = el.scrollHeight + 'px';
    }
  };

  const adjustHeights = () => {
    const el = document.getElementById(id);
    if (el) {
      el.style.height = 'fit-content';
      el.style.height = el.scrollHeight + 'px';
    }
  };

  // const adjustAllHeights = () => {

  // }

  const adjustHeightListeners = [
    'onKeyUp',
    'onPaste',
    'onCut',
    'onDrop',
  ].reduce((acc, key) => {
    acc[key] = adjustHeight;
    return acc;
  }, {});

  useEffect(() => {
    setTimeout(() => {
      adjustHeights();
    });
  }, [itemsCount]);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     adjustAllHeights();
  //   })
  // },[orderChange])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', adjustHeights);
    adjustHeights();
    return () => window.removeEventListener('resize', adjustHeights);
  }, []);

  return (
    <Content data-cy={`${dataCy}_wrapper`} id={`${id}_wrapper`}>
      <Input
        {...adjustHeightListeners}
        data-cy={dataCy}
        id={id}
        disabled={disabled}
        rows={1}
        // rows={
        //   value && value.length > rowsConst
        //     ? Math.ceil(value.length / rowsConst)
        //     : 1
        // }
        value={value !== null && value !== undefined ? value : ''}
        onChange={(event: any) => {
          setCursor(event.target.selectionStart);
          onChange(name, event.target.value, event);
        }}
        onBlur={() => {
          onBlur && onBlur(value);
          setTimeout(() => setFocused(false), 150);
        }}
        bold={bold}
        onClick={() => (focused ? '' : setFocused(true))}
        onFocus={() => {
          onFocus();
          setTimeout(() => {
            setFocused(true);
          }, 0);
        }}
        autoCapitalize={autoCapitalize}
        textTransform={textTransform}
        ref={inputRef}
        style={titleStyles}
      />
      {focused && !!resultSearched.length && (
        <DataContent>
          {resultSearched.map((item: any, index: number) => (
            <ItemContent
              key={`autocomplete_item_${name}_${index}`}
              index={index}
              isSelected={item.value === value}
              onClick={(e) => {
                onChange(name, item.value, e);
                setFocused(false);
                setTimeout(() => adjustHeights());
                setTimeout(() => {
                  onBlur(item.value);
                }, 150);
              }}
            >
              {item.value}
            </ItemContent>
          ))}
        </DataContent>
      )}
    </Content>
  );
};
