import React from 'react';
import { useEffect } from 'react';
import EventEmitter from 'core/utils/events';
import { setCookie, deleteAllCookies } from '../utils/cookiesUtils';
import { ANAGO_CLEANSOURCE_DEV_LOGIN_URL } from '../utils/constants';

const env = process.env;

export const useAuthenticationHandler = () => {
  const [error, setError] = React.useState();

  const handle = (err) => {
    console.log(err, 'err');
    if (err && err.status == 503) {
      deleteAllCookies();
      setCookie('anago_redirect_after_login', window.location.href);
      window.location.href =
        env.REACT_APP_BACKEND_URL || ANAGO_CLEANSOURCE_DEV_LOGIN_URL;
      return;
    }
    if (err && (err.status == 401 || err.status == 403)) {
      setError(err);
    }
  };

  useEffect(() => {
    EventEmitter.on('SERVER_ERROR', handle);
    return () => {
      EventEmitter.removeListener('SERVER_ERROR', handle);
    };
  }, []);

  return { error, setError, handle };
};
