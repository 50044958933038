import { takeEvery, takeLatest, put, select, call } from 'redux-saga/effects';
import { IReduxState, IArea, IAreaItem, IAreaPhoto } from 'core/interfaces';
import { getDefaultAreaItems, axiosInstance, getQueryString } from 'core/utils';
import { Types } from './types';
import {
  ICreate,
  IChangeAreaItemRange,
  IRemoveArea,
  IRemoveAreaItem,
  IChangeAreaItem,
  IAddAreaPhoto,
  IRemoveAreaPhoto,
  IGetEditFormRequest,
  IInspectForm,
  IStateToProps,
  IGetInspectionsRequest,
  IArchiveFormRequest,
  ISaveReportRequest,
  ISuccessOnSave,
  IGetNextInspectionPageRequest,
  IUpdateAreaItems,
  IUpdateArea,
  IWaterStreetData,
} from '../interfaces';
import { InspectFormActions } from './actions';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import yupToObject from 'yup-to-object';
import { capitalizeInput } from 'core/utils/formatInputs';
import mapValues from 'lodash.mapvalues';
import imageCompression from 'browser-image-compression';
import { blobToFile, dataURItoBlob } from '../../../core/utils/blobToFile';
const env = process.env;

// get state
const getCurrentReport = (state: IReduxState) => state.inspectForm;
const getCurrentAreas = (state: IReduxState) => state.inspectForm.Areas;
const getSelectedArea = (state: IReduxState) => state.inspectForm.selectedArea;

function updateKeys(obj, propsToUpdate) {
  const updatedObj = {};

  for (const key in obj) {
    if (propsToUpdate.includes(key)) {
      updatedObj[key.charAt(0) + key.slice(1)] = obj[key];
    } else {
      updatedObj[key] = obj[key];
    }
  }

  console.log(updatedObj, 'updatedObj');

  return updatedObj;
}

// watchers

export function* watchAddArea() {
  yield takeEvery(Types.ADD_AREA_REQUEST, handleAddArea);
}

export function* watchAddAreaItem() {
  yield takeEvery(Types.ADD_AREA_ITEM_REQUEST, handleAddAreaItem);
}

export function* watchUpdateAreaItemRange() {
  yield takeEvery(
    Types.UPDATE_AREA_ITEM_RANGE_REQUEST,
    handleUpdateAreItemRange
  );
}

export function* watchRemoveArea() {
  yield takeEvery(Types.REMOVE_AREA_REQUEST, handleRemoveArea);
}

export function* watchUpdateAreaItems() {
  yield takeEvery(Types.UPDATE_AREA_ITEMS, handleUpdateAreaItems);
}

export function* watchUpdateArea() {
  yield takeEvery(Types.UPDATE_AREA, handleUpdateArea);
}

export function* watchRemoveAreaItem() {
  yield takeEvery(Types.REMOVE_AREA_ITEM_REQUEST, handleRemoveAreaItem);
}

export function* watchCalculateRates() {
  yield takeEvery(Types.CALCULATE_RATE_REQUEST, handleCalculateRates);
}

export function* watchUpdateDuplicatedAreas() {
  yield takeEvery(Types.UPDATE_DUPLICATED_AREAS, handleUpdateDuplicatedAreas);
}

export function* watchAddAreaPhoto() {
  yield takeEvery(Types.ADD_AREA_PHOTO_REQUEST, handleAddAreaPhoto);
}

export function* watchRemoveAreaPhoto() {
  yield takeEvery(Types.REMOVE_AREA_PHOTO_REQUEST, handleRemoveAreaPhoto);
}

export function* watchSaveReport() {
  yield takeEvery(Types.SAVE_REPORT_REQUEST, handleSaveReport);
}

export function* watchGetEditFormRequest() {
  yield takeEvery(Types.GET_EDIT_FORM_REQUEST, handleGetEditForm);
}

export function* watchGetPublicEditFormRequest() {
  yield takeEvery(Types.GET_PUBLIC_EDIT_FORM_REQUEST, handleGetPublicEditForm);
}

export function* watchGetInspections() {
  yield takeEvery(Types.GET_INSPECTIONS_REQUEST, handleGetInspections);
}

export function* watchGetNextInspectionsPage() {
  yield takeEvery(
    Types.GET_NEXT_INSPECTIONS_PAGE_REQUEST,
    handleGetNextInspectionsPage
  );
}

export function* watchArchiveForm() {
  yield takeEvery(Types.ARCHIVE_FORM_REQUEST, handleArchiveForm);
}

export function* watchDuplicateForm() {
  yield takeEvery(Types.DUPLICATE, handleDuplicateReport);
}

export function* watchUpdateTemplateForm() {
  yield takeEvery(Types.UPDATE_TEMPLATE, handleUpdateTemplate);
}

export function* watchCreateNewTemplateForm() {
  yield takeEvery(
    Types.CREATE_TEMPLATE_FROM_SENT,
    handleCreateTemplateFromSent
  );
}

export function* watchCreateDraftFromTemplateForm() {
  yield takeEvery(
    Types.CREATE_DRAFT_FROM_TEMPLATE,
    handleCreateDraftFromTemplate
  );
}

export function* watchCreateNewTemplateFromDraftForm() {
  yield takeEvery(
    Types.CREATE_TEMPLATE_FROM_DRAFT,
    handleCreateTemplateFromDraft
  );
}

export function* watchSendEmailRequest() {
  yield takeLatest(Types.SEND_EMAIL_REQUEST, handleSendEmail);
}

function* handleAddArea(action: ICreate) {
  try {
    const { name } = action.payload;
    const currentAreas = yield select(getCurrentAreas);
    const newArea: IArea = {
      AreaId: 0,
      Name: name,
      Amount: '',
      RatingAverage: 0,
      RatingTotal: 0,
      Selected: false,
      DomainFloorTypeId: 0,
      Length: 0,
      Width: 0,
      TotalSqFt: 0,
      InspectionReportId: 0,
      AllowSelection: false,
      AreaItems: getDefaultAreaItems(name),
      AreaPhotos: [],
    };
    const newAreas: IArea[] = [...currentAreas];
    newAreas.push(newArea);
    yield put(InspectFormActions.setArea(newAreas));
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleUpdateAreItemRange(action: IChangeAreaItemRange) {
  try {
    const { areaItem, grade } = action.payload;
    const currentAreas: IArea[] = yield select(getCurrentAreas);
    const selectedArea: IArea = yield select(getSelectedArea);

    // const areaIndex = currentAreas.indexOf(selectedArea);
    // const areaItemIndex = selectedArea.AreaItems.indexOf(areaItem);
    const areaIndex = currentAreas.findIndex(
      (area: IArea) => area.Name === selectedArea.Name
    );
    const areaItemIndex = selectedArea.AreaItems.findIndex(
      (area: IAreaItem) => area.Name === areaItem.Name
    );
    const newAreas: IArea[] = [...currentAreas];
    newAreas[areaIndex].AreaItems[areaItemIndex].Grade = grade;

    // const newAreas: IArea[] = currentAreas.map((item: IArea) => {
    //   if(item.Name === selectedArea.Name) {
    //     return {
    //       ...item,
    //       AreaItems: item.AreaItems.map((area: IAreaItem) => {
    //         console.log(area.Name === areaItem.Name)
    //         if(area.Name === areaItem.Name) {
    //           return {
    //             ...area,
    //             Grade: grade
    //           }
    //         }
    //         return area
    //       })
    //     }
    //   }
    //   return item
    // })

    yield put(InspectFormActions.setArea(newAreas));
    yield put(InspectFormActions.calculateRatesRequest());
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleRemoveArea(action: IRemoveArea) {
  try {
    const { area } = action.payload;
    const currentAreas: IArea[] = yield select(getCurrentAreas);

    const areaIndex = currentAreas.indexOf(area);

    const newAreas: IArea[] = [...currentAreas];
    newAreas.splice(areaIndex, 1);

    yield put(InspectFormActions.setArea(newAreas));
    yield put(InspectFormActions.updateDuplicatedAreas());
    yield put(InspectFormActions.calculateRatesRequest());
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleUpdateDuplicatedAreas() {
  try {
    const currentAreas: IArea[] = yield select(getCurrentAreas);
    const result: IArea[] = [];
    let memo = {};

    currentAreas.forEach((item: IArea) => {
      let key = item.Name.toLowerCase()
        .replace(/\((.+?)\)/g, '')
        .trim();
      memo = { ...memo, [key]: memo[key] ? memo[key] + 1 : 1 };
      result.push({
        ...item,
        Name:
          memo[key] > 1
            ? `${item.Name.replace(/\((.+?)\)/g, '').trim()} (${memo[key]})`
            : item.Name,
      });
    });

    yield put(InspectFormActions.setArea(result));
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleUpdateAreaItems(action: IUpdateAreaItems) {
  try {
    const { areaItems } = action.payload;
    const currentAreas: IArea[] = yield select(getCurrentAreas);
    const selectedArea: IArea = yield select(getSelectedArea);

    // const areaIndex = currentAreas.indexOf(selectedArea);
    // const newAreas: IArea[] = [
    //   ...currentAreas.filter((_, index) => index !== areaIndex),
    //   { ...selectedArea, AreaItems: [...areaItems] }
    // ];
    const newAreas2: IArea[] = currentAreas.map((item: IArea) => {
      if (item.Name === selectedArea.Name) {
        return {
          ...item,
          AreaItems: [...areaItems],
        };
      }
      return item;
    });

    const newAreaSelected = newAreas2.filter(
      (item: IArea) => item.Name === selectedArea.Name
    )[0];
    yield put(InspectFormActions.setAreaSelected(newAreaSelected));

    yield put(InspectFormActions.setArea(newAreas2));
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleUpdateArea(action: IUpdateArea) {
  try {
    const { area } = action.payload;
    const currentAreas: IArea[] = yield select(getCurrentAreas);
    const selectedArea: IArea = yield select(getSelectedArea);

    const areaIndex = currentAreas.indexOf(selectedArea);
    const newAreas = currentAreas.filter((_, index) => index !== areaIndex);
    newAreas.splice(areaIndex, 0, area);

    // const newAreas: IArea[] = currentAreas.filter((_, index) => index !== areaIndex))
    // const newAreas: IArea[] = [
    //   ...currentAreas.filter((_, index) => index !== areaIndex),
    //   area
    // ];
    yield put(InspectFormActions.setAreaSelected(area));
    yield put(InspectFormActions.setArea(newAreas));
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleRemoveAreaItem(action: IRemoveAreaItem) {
  try {
    const { areaItem } = action.payload;
    const currentAreas: IArea[] = yield select(getCurrentAreas);
    const selectedArea: IArea = yield select(getSelectedArea);

    const areaIndex = currentAreas.indexOf(selectedArea);
    const areaItemIndex = selectedArea.AreaItems.indexOf(areaItem);

    const newAreas: IArea[] = [...currentAreas];
    newAreas[areaIndex].AreaItems.splice(areaItemIndex, 1);

    yield put(InspectFormActions.setArea(newAreas));
    yield put(InspectFormActions.calculateRatesRequest());
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleAddAreaItem(action: IChangeAreaItem) {
  try {
    const { name } = action.payload;
    const currentAreas = yield select(getCurrentAreas);
    const selectedArea: IArea = yield select(getSelectedArea);
    // const areaIndex = currentAreas.indexOf(selectedArea);
    // const areaItemIndex = selectedArea.AreaItems.indexOf(areaItem);
    // const areaIndex = currentAreas.findIndex((area: IArea) => area.Name === selectedArea.Name)

    const newAreaItem: IAreaItem = {
      AreaItemId: 0,
      Name: name,
      Grade: 0,
      AreaId: 0,
    };

    // yield put(InspectFormActions.setItem(newAreaItem));
    // const newAreas: IArea[] = [...currentAreas];
    // newAreas[areaIndex].AreaItems.push(newAreaItem);

    const newAreas2: IArea[] = currentAreas.map((item: IArea) => {
      if (item.Name === selectedArea.Name) {
        return {
          ...item,
          AreaItems: [...item.AreaItems, newAreaItem],
        };
      }
      return item;
    });

    const newAreaSelected = newAreas2.filter(
      (item: IArea) => item.Name === selectedArea.Name
    )[0];

    // yield put(InspectFormActions.setArea(newAreas));
    yield put(InspectFormActions.setAreaSelected(newAreaSelected));
    yield put(InspectFormActions.setArea(newAreas2));
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleCalculateRates() {
  try {
    const currentAreas = yield select(getCurrentAreas);
    const newAreas = [...currentAreas];
    let grandTotal = 0;
    let facilityRating = 0;
    let ratingAverageTotal = 0;

    newAreas.forEach((area: IArea) => {
      // initial area rate
      let areaRate = 0;
      // gets all area items rates and add to areaRate
      area.AreaItems.forEach((areaItem: IAreaItem, index: number) => {
        areaRate = areaRate + areaItem.Grade;
      });
      // set the area rating total
      area.RatingTotal = areaRate;
      // calculates the average
      const areaGradedlength: number = area.AreaItems.filter(
        (area: IAreaItem) => area.Grade !== 0
      ).length;
      area.RatingAverage =
        areaGradedlength !== 0 ? areaRate / areaGradedlength : 0;
      // sums up the total rating average to perform the facility rating below
      ratingAverageTotal = ratingAverageTotal + area.RatingAverage;

      // add the area rate to granTotal
      grandTotal = grandTotal + areaRate;
    });
    const numberOfAreasRated = newAreas.filter(
      (item: IArea) => item.RatingTotal !== 0
    ).length;

    facilityRating =
      numberOfAreasRated !== 0 ? ratingAverageTotal / numberOfAreasRated : 0;

    yield put(
      InspectFormActions.calculateRatesSuccess(
        newAreas,
        grandTotal,
        facilityRating
      )
    );
  } catch (err) {
    yield put(InspectFormActions.calculateRatesFailure(err));
  }
}

function* handleAddAreaPhoto(action: IAddAreaPhoto) {
  try {
    const { file, fileUrl, lastFile } = action.payload;

    // File compression
    // const options = {
    //   maxSizeMB: env.REACT_APP_MAX_IMAGE_SIZE_MB || 1,
    //   maxWidthOrHeight: env.REACT_APP_MAX_IMAGE_SIZE_PX || 1280
    // };
    // const dataToFile = yield imageCompression.getFilefromDataUrl(fileUrl);
    // const compressedBlob = yield imageCompression(dataToFile, options);
    // const compressedFile = blobToFile(compressedBlob, file.name);
    // const fileToData = yield imageCompression.getDataUrlFromFile(
    //   compressedFile
    // );

    const compressedBlob = dataURItoBlob(fileUrl);
    const compressedFile = blobToFile(compressedBlob, file.name);

    const currentAreas = yield select(getCurrentAreas);
    const selectedArea: IArea = yield select(getSelectedArea);
    const areaIndex = currentAreas.indexOf(selectedArea);

    const newAreaPhoto: IAreaPhoto = {
      AreaPhotoId: 0,
      PathAndFileName: '',
      ImageId: 0,
      AreaId: 0,
      IsSelected: false,
      File: compressedFile,
      FileUrl: fileUrl,
    };
    const newAreas: IArea[] = [...currentAreas];
    newAreas[areaIndex].AreaPhotos.push(newAreaPhoto);
    yield put(InspectFormActions.setArea(newAreas));
    if (lastFile) {
      yield put(InspectFormActions.setLoadingState(false));
    }
  } catch (err) {
    yield put(InspectFormActions.setError(err));

    yield put(InspectFormActions.setLoadingState(false));
  }
}

function* handleRemoveAreaPhoto(action: IRemoveAreaPhoto) {
  try {
    const { areaPhoto } = action.payload;
    const currentAreas: IArea[] = yield select(getCurrentAreas);
    const selectedArea: IArea = yield select(getSelectedArea);

    const areaIndex = currentAreas.indexOf(selectedArea);
    const areaPhotoIndex = selectedArea.AreaPhotos.indexOf(areaPhoto);

    const newAreas: IArea[] = [...currentAreas];
    newAreas[areaIndex].AreaPhotos.splice(areaPhotoIndex, 1);

    yield put(InspectFormActions.setArea(newAreas));
  } catch (err) {
    yield put(InspectFormActions.setError(err));
  }
}

function* handleGetEditForm(action: IGetEditFormRequest) {
  try {
    const { id, locationId, isTemplate, isOptInCleanSuite } = action.payload;

    const formResponse: IInspectForm = yield call(getReportCall, id);

    const waterStreetResponse: IWaterStreetData = yield call(
      getWaterStreetDataCall,
      locationId
    );

    for (const area of formResponse.Areas) {
      for (const areaPhoto of area.AreaPhotos) {
        const res = yield call(getImageCall, areaPhoto.ImageId);
        areaPhoto.PathAndFileName = res.FileName;
      }
    }

    const waterStreetData = waterStreetResponse || {};
    const waterStreetComplementaryData: Partial<IWaterStreetData> = {};

    Object.entries(waterStreetData).forEach(([key, value]) => {
      if (!value) {
        return;
      }

      waterStreetComplementaryData[key] = value;
    });

    const data = {
      ...formResponse,
      ...((!formResponse.SentStatus || locationId) &&
        waterStreetComplementaryData),
      AccountName: waterStreetData.BusinessName || formResponse.AccountName,
      AccountAddress: waterStreetData.Address || formResponse.AccountAddress,
      Date: formResponse.SentStatus
        ? formResponse.Date
        : new Date().toISOString().split('T')[0],
      originalPid: formResponse.PID,
    };

    if (isOptInCleanSuite && data.IsTemplate) {
      data['InspectionReportId'] = 0;
    }

    if (
      isOptInCleanSuite &&
      waterStreetComplementaryData &&
      !waterStreetComplementaryData.PID
    ) {
      yield put(InspectFormActions.setLocationIdWarningVisible(true));
    }

    yield put(
      InspectFormActions.getEditFormSuccess({
        ...data,
        IsTemplate: Boolean(isTemplate),
        IsCityTemplate: Boolean(isTemplate),
      })
    );

    yield put(InspectFormActions.calculateRatesRequest());
  } catch (error) {
    yield put(InspectFormActions.getEditFormFailure(error));
  }
}

function* handleGetPublicEditForm(action: IGetEditFormRequest) {
  try {
    const { id } = action.payload;
    const response: IInspectForm = yield call(getPublicReportCall, id);
    for (const area of response.Areas) {
      for (const areaPhoto of area.AreaPhotos) {
        const res = yield call(getImageCall, areaPhoto.ImageId);

        areaPhoto.PathAndFileName = res.FileName;
      }
    }
    const data = response;

    yield put(InspectFormActions.getEditFormSuccess(data));
    yield put(InspectFormActions.calculateRatesRequest());
  } catch (error) {
    yield put(InspectFormActions.getEditFormFailure(error));
  }
}

function validateSchema(schema: any, body: any) {
  return schema
    .validate(body, { abortEarly: false })
    .then((response: any) => ({ response }))
    .catch((yupError: any) => {
      return { errors: yupToObject(yupError) };
    });
}

function* handleSaveReport(action: ISaveReportRequest) {
  const { type, newForm } = action.payload;

  try {
    let currentReport: IStateToProps = yield select(getCurrentReport);
    const franchiseId = getQueryString(window.location.href, 'FranchiseId');
    let AdjustedAreas = currentReport.Areas;
    if (type === 'template' || type === 'citytemplate') {
      AdjustedAreas = currentReport.Areas.map((item: any) => {
        if (item.AreaItems) {
          return {
            ...item,
            AreaItems: item.AreaItems.map((area: IAreaItem) => ({
              ...area,
              Grade: -1,
              Name: capitalizeInput(area.Name),
            })),
            AreaPhotos: [],
            Name: capitalizeInput(item.Name),
          };
        }
        return item;
      });
    } else {
      AdjustedAreas = currentReport.Areas.map((item: any) => {
        if (item.AreaItems) {
          return {
            ...item,
            AreaItems: item.AreaItems.map((area: IAreaItem) => ({
              ...area,
              Grade: area.Grade - 1,
              Name: capitalizeInput(area.Name),
            })),
            Name: capitalizeInput(item.Name),
          };
        }
        return item;
      });
    }

    const fieldsToCapitalize = [
      'Contact',
      'AccountAddress',
      'AccountName',
      'Owner',
      'Suite',
      'City',
      'FranchiseOwnerName',
    ];
    const fieldsToUppercase = ['PID', 'Zip', 'State'];

    currentReport = mapValues(currentReport, (value, key) => {
      const validValue = value !== null ? value : '';
      let newValue = validValue;
      if (fieldsToCapitalize.includes(key))
        newValue = capitalizeInput(validValue);
      else if (fieldsToUppercase.includes(key))
        newValue = validValue.toUpperCase();
      return newValue;
    });

    const reportBody: IInspectForm = {
      InspectionReportId:
        newForm === 'saveas' ||
        (newForm === 'new' && !currentReport.isDraft) ||
        currentReport.transformedToDraft
          ? 0
          : currentReport.InspectionReportId,
      PID: currentReport.PID.toUpperCase(),
      InspectionClassNumber:
        currentReport.InspectionClassNumber === 'Inspection Class #'
          ? ''
          : currentReport.InspectionClassNumber, // so we display blank on the FirstForm component if user has not selected any option for this field
      ContactForm: currentReport.ContactForm,
      Date: currentReport.Date,
      InspectedBy: currentReport.InspectedByInStore,
      // Owner: currentReport.Owner,
      Owner: currentReport.FranchiseOwnerName,
      AccountName: currentReport.AccountName,
      AccountAddress: currentReport.AccountAddress,
      Suite: currentReport.Suite,
      City: currentReport.City,
      State: currentReport.State,
      Zip: currentReport.Zip,
      IsTemplate: type === 'template' || type === 'citytemplate' ? true : false,
      IsCityTemplate: type === 'citytemplate' ? true : false,
      // FranchiseId: currentReport.FranchiseId,
      FranchiseId: Number(currentReport.MasterCity) || Number(franchiseId),
      DaysCleanedMonday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Mon'),
      DaysCleanedTuesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Tue'),
      DaysCleanedWednesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Wed'),
      DaysCleanedThursday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Thu'),
      DaysCleanedFriday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Fri'),
      DaysCleanedSaturday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sat'),
      DaysCleanedSunday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sun'),
      IsMonthlyCleaning: currentReport.frequencyService === 'monthly',
      TimesPerMonth:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimesPerMonth
          : '',
      TimeOfDay:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimeOfDay
          : '',
      Contact: currentReport.Contact,
      Phone: currentReport.Phone,
      Notes: currentReport.Notes,
      CreatedBy:
        type === 'template'
          ? null
          : currentReport.userId || currentReport.CreatedBy,
      FranchiseOwnerName: currentReport.FranchiseOwnerName,
      Time:
        currentReport.frequencyService !== 'monthly'
          ? currentReport.frequency.timeWindow
          : '',
      grandTotal: currentReport.GrandTotal,
      Areas: AdjustedAreas,
      // SentStatus: type === 'draft' ? false : true,
      SentStatus:
        type === 'template' || type === 'citytemplate'
          ? null
          : type === 'draft'
          ? false
          : true,
      MasterCity: Number(currentReport.MasterCity) || Number(franchiseId),

      // ClientInfo
      CreatedAt: currentReport.createdAt,
      LastUpdate: currentReport.lastUpdate,
      Address: currentReport.Address,
      MobilePhone: currentReport.MobilePhone,
      County: currentReport.County,
      WalkThruName: currentReport.WalkThruName,
      WalkThruEmail: currentReport.WalkThruEmail,
      WalkThruTitle: currentReport.WalkThruTitle,
      DecisionMakerName: currentReport.DecisionMakerName,
      DecisionMakerEmail: currentReport.DecisionMakerEmail,
      DecisionMakerTitle: currentReport.DecisionMakerTitle,
      SicId: currentReport.sicId,
      SicName: currentReport.sicName,
      ExternalId: currentReport.externalId,
      LocationId: currentReport.locationId,
      WaterStreetUpdateUrl: currentReport.waterStreetUpdateUrl,
      ContactPhoneExtension: currentReport.ContactPhoneExtension,
      ContactPhoneType: currentReport.ContactPhoneType,
      DecisionMakerFirstName: currentReport.DecisionMakerFirstName,
      DecisionMakerLastName: currentReport.DecisionMakerLastName,
      DecisionMakerPhone: currentReport.DecisionMakerPhone,
      DecisionMakerPhoneExtension: currentReport.DecisionMakerPhoneExtension,
      DecisionMakerPhoneType: currentReport.DecisionMakerPhoneType,
      WalkThruFirstName: currentReport.WalkThruFirstName,
      WalkThruLastName: currentReport.WalkThruLastName,
      WalkThruPhone: currentReport.WalkThruPhone,
      WalkThruPhoneExtension: currentReport.WalkThruPhoneExtension,
      WalkThruPhoneType: currentReport.WalkThruPhoneType,
    };

    const saveErrorSchema = Yup.object().shape({
      PID: Yup.string().required('Required field'),
    });
    const completeErrorSchema = Yup.object().shape({
      PID: Yup.string().required('Required field'),
      Date: Yup.string().required('Required field'),
      AccountName: Yup.string().required('Required field'),
      FranchiseOwnerName: Yup.string().required('Required field'),
      MasterCity: Yup.string().required('Required field'),
      grandTotal: Yup.number().moreThan(0, 'You must rate at least one area'),
    });

    let yupErrors;

    if (type === 'sent') {
      const { response, errors } = yield call(
        validateSchema,
        completeErrorSchema,
        reportBody
      );
      console.log('errors', errors, response);
      yupErrors = errors || null;
    } else {
      const { errors } = yield call(
        validateSchema,
        saveErrorSchema,
        reportBody
      );
      yupErrors = errors || null;
    }

    yield put(InspectFormActions.setFormErrors(yupErrors));

    const errorMessage = [];

    if (reportBody.PID === '') {
      errorMessage.push('PID');
    }

    if (type === 'sent' && reportBody.Date === '') {
      errorMessage.push('Date');
    }
    // verify below only if 'finish' button was pressed
    if (
      (type === 'sent' || type === 'citytemplate') &&
      reportBody.AccountName === ''
    ) {
      errorMessage.push('Location Name');
    }
    if (type === 'sent' && reportBody.grandTotal === 0) {
      errorMessage.push('Facility Rating');
    }

    // display alert with error message, and change loading to false
    const errorLength = errorMessage.length;

    if (errorLength > 0) {
      yield put(InspectFormActions.setWarningModalState(true, errorMessage));
      // alert(finalMessage);
      yield put(InspectFormActions.validationFailure());
    } else {
      // send photos and report to backend
      for (const area of reportBody.Areas) {
        for (const areaPhoto of area.AreaPhotos) {
          if (areaPhoto.File) {
            const res = yield call(saveFileCall, areaPhoto);
            areaPhoto.PathAndFileName = res.Uri;
            // areaPhoto.AreaPhotoId = res.Id;
            areaPhoto.ImageId = res.Id;
          }
        }
      }

      const res = yield call(saveReportCall, reportBody);

      yield put(
        InspectFormActions.saveReportSuccess(
          res,
          type,
          newForm === 'new' || newForm === 'saveas'
        )
      );

      yield put(InspectFormActions.setNeedsSaving(false));

      yield put(InspectFormActions.setInspectionReportId(res.originalId));

      let successMessage = '';

      if (type == 'citytemplate') {
        successMessage =
          'Template saved successfully! This template is now available for use and can be edited from the Templates Menu.';
      } else if (type == 'template') {
        successMessage =
          'Template saved successfully! This template is now available for use and can be edited from the Templates Menu.';
      } else if (type == 'sent') {
        successMessage = 'Your sent proposal was saved successfully';
      } else if (type == 'draft') {
        successMessage = 'Your draft was saved successfully';
      } else {
        successMessage = 'Saved Successfully';
      }

      if (type !== 'sent') {
        yield toast.info(successMessage);
        yield put(InspectFormActions.setIsNotSent());
      } else {
        yield put(InspectFormActions.setIsSent());
      }

      if (currentReport.transformedToDraft && !newForm) {
        yield put(InspectFormActions.revertTransformedToDraftState());
      }

      if (type === 'template' || type === 'citytemplate') {
        yield put(InspectFormActions.revertTransformedToDraftState());
        yield put(InspectFormActions.setIsTemplate(true));

        if (type === 'citytemplate') {
          yield put(InspectFormActions.setIsCityTemplate(true));
        } else {
          yield put(InspectFormActions.setIsCityTemplate(false));
        }
      } else {
        yield put(InspectFormActions.setIsTemplate(false));
      }
    }
  } catch (error) {
    yield put(InspectFormActions.saveReportFailure(error));
  }
}

function* handleSendEmail(action: ISuccessOnSave) {
  try {
    const { successResult } = action.payload;

    const response = yield call(
      sendEmailCall,
      successResult.originalId,
      successResult.pdfUri
    );

    yield put(InspectFormActions.sendEmailSuccess());
  } catch (error) {
    yield put(InspectFormActions.sendEmailFailure());
  }
}

function* handleGetInspections(action: IGetInspectionsRequest) {
  try {
    const { userId } = action.payload;
    const response = yield call(getInspectionsCall, userId);
    const {
      Drafts,
      Templates,
      CityTemplates,
      Sents,
      TotalDrafts,
      TotalSents,
      TotalTemplates,
      TotalCityTemplates,
    } = response;

    const draftItems: IInspectForm[] = Drafts;
    const cityTemplateItems: IInspectForm[] = CityTemplates;
    const sentItems: IInspectForm[] = Sents;

    for (const form of draftItems) {
      form.FacilityRating = calculateFacilityRating(form);
    }

    for (const form of cityTemplateItems) {
      form.FacilityRating = calculateFacilityRating(form);
    }

    for (const form of sentItems) {
      form.FacilityRating = calculateFacilityRating(form);
    }

    yield put(
      InspectFormActions.getInspectionsSuccess(
        sentItems,
        draftItems,
        cityTemplateItems,
        TotalDrafts,
        TotalSents,
        TotalCityTemplates
      )
    );
  } catch (err) {
    yield put(InspectFormActions.getInspectionsFailure(err));
  }
}

function* handleGetNextInspectionsPage(action: IGetNextInspectionPageRequest) {
  try {
    const {
      type,
      userId,
      page,
      sortByMostRecent,
      dateMostRecent,
      azAscending,
    } = action.payload;
    const response = yield call(getInspectionsCall, userId, page, type);
    const {
      Drafts,
      Templates,
      Sents,
      CityTemplates,
      TotalDrafts,
      TotalSents,
      TotalTemplates,
      TotalCityTemplates,
    } = response;

    const draftItems: IInspectForm[] = Drafts || [];
    const templateItems: IInspectForm[] = Templates || [];
    const cityTemplateItems: IInspectForm[] = CityTemplates || [];
    const sentItems: IInspectForm[] = Sents || [];

    for (const form of draftItems) {
      form.FacilityRating = calculateFacilityRating(form);
    }

    for (const form of templateItems) {
      form.FacilityRating = calculateFacilityRating(form);
    }

    for (const form of sentItems) {
      form.FacilityRating = calculateFacilityRating(form);
    }

    yield put(
      InspectFormActions.getNextInspectionPageSuccess(
        sentItems,
        draftItems,
        templateItems,
        cityTemplateItems,
        TotalDrafts,
        TotalSents,
        TotalTemplates,
        TotalCityTemplates
      )
    );
    yield put(
      InspectFormActions.sortInspections(
        sortByMostRecent,
        dateMostRecent,
        azAscending
      )
    );
  } catch (err) {
    yield put(InspectFormActions.getInspectionsFailure(err));
  }
}

function* handleArchiveForm(action: IArchiveFormRequest) {
  try {
    const { form } = action.payload;
    yield call(archiveFormCall, form.InspectionReportId);
    yield put(InspectFormActions.archiveFormSuccess());
  } catch (err) {
    yield put(InspectFormActions.archiveFormFailure(err));
  }
}

// calls

const archiveFormCall = async (formId: number) => {
  const res = await axiosInstance
    .get(`InspectionReport/SetInspectioArchived/${formId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
  return res;
};

const getInspectionsCall = async (
  userId: string,
  page: number = 0,
  type: string = null
) => {
  let uri = '';
  const franchiseId = getQueryString(window.location.href, 'FranchiseId');

  switch (type) {
    case 'Drafts':
      uri = 'GetDraftsReportFormByUser';
      break;
    case 'Templates':
      uri = 'GetTemplatesReportFormByUser';
      break;
    case 'CityTemplates':
      uri = 'GetCityTemplatesByUser';
      break;
    case 'Sents':
      uri = 'GetSentsReportFormByUser';
      break;
    default:
      uri = 'GetInspectionsReportFormByUser';
      break;
  }

  const res = await axiosInstance
    .get(
      `InspectionReport/${uri}?id=${userId}&page=${page}&pid=&franchiseId=${franchiseId}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
  return res;
};

const sendEmailCall = async (id: number, pdfUri: string) => {
  try {
    // change this endpoint once its created on backend
    // const response = await axios.post('https://anago-forms.api.rc.anago.conceptsol.com/api/InspectionReport/SaveInspectionReport', { id, pdfUri }, {
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // })
    // return response.data
  } catch (error) {
    return error;
  }
};

const saveReportCall = async (report: any) => {
  const res = await axiosInstance
    .post('InspectionReport/saveInspectionReportAnagoForms', report, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
  return res;
};

const saveFileCall = async (areaPhoto: IAreaPhoto) => {
  const formData = new FormData();
  formData.append('name', areaPhoto.File!);
  const res = await axiosInstance
    .post('InspectionReport/UploadSignature', formData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
  return res;
};

const getImageCall = async (id: number) => {
  const res = await axiosInstance
    .get(`InspectionReport/getImageUri/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
  return res;
};

const getReportCall = async (id: string) => {
  const res = await axiosInstance
    .get(`InspectionReport/GetInspectionReportForm/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
  return res;
};

const getWaterStreetDataCall = async (locationId: string) => {
  if (!locationId) {
    return;
  }

  const res = await axiosInstance
    .get(`InspectionReport/WaterStreet/GetLocationByID/${locationId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res.data.WaterStreet;
    })
    .catch((error) => {
      return error;
    });

  return res;
};

const getPublicReportCall = async (id: string) => {
  const res = await axiosInstance
    .get(`InspectionReport/Client/GetInspectionReportForm/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
  return res;
};

export const calculateFacilityRating = (form: IInspectForm) => {
  // const { Areas } = form;
  // const newAreas = [...Areas];
  // let grandTotal = 0;
  // let facilityRating = 0;
  // let ratingAverageTotal = 0;

  // newAreas.forEach((area: IArea) => {
  //   // initial area rate
  //   let areaRate = 0;
  //   // gets all area items rates and add to areaRate
  //   area.AreaItems.forEach((areaItem: IAreaItem, index: number) => {
  //     areaRate = areaRate + areaItem.Grade;
  //   });
  //   // set the area rating total
  //   area.RatingTotal = areaRate;
  //   // calculates the average
  //   const areaGradedlength: number = area.AreaItems.filter(
  //     (area: IAreaItem) => area.Grade !== 0
  //   ).length;
  //   area.RatingAverage =
  //     areaGradedlength !== 0 ? areaRate / areaGradedlength : 0;
  //   // sums up the total rating average to perform the facility rating below
  //   ratingAverageTotal = ratingAverageTotal + area.RatingAverage;

  //   // add the area rate to granTotal
  //   grandTotal = grandTotal + areaRate;
  // });
  // const numberOfAreasRated = newAreas.filter(
  //   (item: IArea) => item.RatingTotal !== 0
  // ).length;

  // facilityRating =
  //   numberOfAreasRated !== 0 ? ratingAverageTotal / numberOfAreasRated : 0;

  return form.OverallRating;
};

function* handleDuplicateReport(action: ISaveReportRequest) {
  const { type, newForm } = action.payload;

  try {
    let currentReport: IStateToProps = yield select(getCurrentReport);

    const franchiseId = getQueryString(window.location.href, 'FranchiseId');

    let AdjustedAreas = currentReport.Areas;

    AdjustedAreas = currentReport.Areas.map((item: any) => {
      if (item.AreaItems) {
        return {
          ...item,
          AreaItems: item.AreaItems.map((area: IAreaItem) => ({
            ...area,
            Grade: area.Grade - 1,
            Name: capitalizeInput(area.Name),
          })),
          Name: capitalizeInput(item.Name),
        };
      }
      return item;
    });

    const fieldsToCapitalize = [
      'Contact',
      'AccountAddress',
      'AccountName',
      'Owner',
      'Suite',
      'City',
      'FranchiseOwnerName',
    ];
    const fieldsToUppercase = ['PID', 'Zip', 'State'];

    currentReport = mapValues(currentReport, (value, key) => {
      const validValue = value !== null ? value : '';
      let newValue = validValue;
      if (fieldsToCapitalize.includes(key))
        newValue = capitalizeInput(validValue);
      else if (fieldsToUppercase.includes(key))
        newValue = validValue.toUpperCase();
      return newValue;
    });

    const reportBody: IInspectForm = {
      InspectionReportId: 0,
      PID: currentReport.PID.toUpperCase(),
      InspectionClassNumber:
        currentReport.InspectionClassNumber === 'Inspection Class #'
          ? ''
          : currentReport.InspectionClassNumber, // so we display blank on the FirstForm component if user has not selected any option for this field
      ContactForm: currentReport.ContactForm,
      Date: currentReport.Date,
      InspectedBy: currentReport.InspectedByInStore,
      // Owner: currentReport.Owner,
      Owner: currentReport.FranchiseOwnerName,
      AccountName: currentReport.AccountName,
      AccountAddress: currentReport.AccountAddress,
      Suite: currentReport.Suite,
      City: currentReport.City,
      State: currentReport.State,
      Zip: currentReport.Zip,
      IsTemplate: false,
      IsCityTemplate: false,
      // FranchiseId: currentReport.FranchiseId,
      FranchiseId: Number(currentReport.MasterCity) || Number(franchiseId),
      DaysCleanedMonday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Mon'),
      DaysCleanedTuesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Tue'),
      DaysCleanedWednesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Wed'),
      DaysCleanedThursday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Thu'),
      DaysCleanedFriday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Fri'),
      DaysCleanedSaturday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sat'),
      DaysCleanedSunday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sun'),
      IsMonthlyCleaning: currentReport.frequencyService === 'monthly',
      TimesPerMonth:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimesPerMonth
          : '',
      TimeOfDay:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimeOfDay
          : '',
      Contact: currentReport.Contact,
      Phone: currentReport.Phone,
      Notes: currentReport.Notes,
      CreatedBy: currentReport.userId || currentReport.CreatedBy,
      FranchiseOwnerName: currentReport.FranchiseOwnerName,
      Time:
        currentReport.frequencyService !== 'monthly'
          ? currentReport.frequency.timeWindow
          : '',
      grandTotal: currentReport.GrandTotal,
      Areas: AdjustedAreas,
      // SentStatus: type === 'draft' ? false : true,
      SentStatus: false,
      MasterCity: Number(currentReport.MasterCity) || Number(franchiseId),

      // ClientInfo
      CreatedAt: currentReport.createdAt,
      LastUpdate: currentReport.lastUpdate,
      Address: currentReport.Address,
      MobilePhone: currentReport.MobilePhone,
      County: currentReport.County,
      WalkThruName: currentReport.WalkThruName,
      WalkThruEmail: currentReport.WalkThruEmail,
      WalkThruTitle: currentReport.WalkThruTitle,
      DecisionMakerName: currentReport.DecisionMakerName,
      DecisionMakerEmail: currentReport.DecisionMakerEmail,
      DecisionMakerTitle: currentReport.DecisionMakerTitle,
      SicId: currentReport.sicId,
      SicName: currentReport.sicName,
      ExternalId: currentReport.externalId,
      LocationId: currentReport.locationId,
      WaterStreetUpdateUrl: currentReport.waterStreetUpdateUrl,
      ContactPhoneExtension: currentReport.ContactPhoneExtension,
      ContactPhoneType: currentReport.ContactPhoneType,
      DecisionMakerFirstName: currentReport.DecisionMakerFirstName,
      DecisionMakerLastName: currentReport.DecisionMakerLastName,
      DecisionMakerPhone: currentReport.DecisionMakerPhone,
      DecisionMakerPhoneExtension: currentReport.DecisionMakerPhoneExtension,
      DecisionMakerPhoneType: currentReport.DecisionMakerPhoneType,
      WalkThruFirstName: currentReport.WalkThruFirstName,
      WalkThruLastName: currentReport.WalkThruLastName,
      WalkThruPhone: currentReport.WalkThruPhone,
      WalkThruPhoneExtension: currentReport.WalkThruPhoneExtension,
      WalkThruPhoneType: currentReport.WalkThruPhoneType,
    };

    const saveErrorSchema = Yup.object().shape({
      PID: Yup.string().required('Required field'),
    });
    const completeErrorSchema = Yup.object().shape({
      PID: Yup.string().required('Required field'),
      Date: Yup.string().required('Required field'),
      AccountName: Yup.string().required('Required field'),
      FranchiseOwnerName: Yup.string().required('Required field'),
      MasterCity: Yup.string().required('Required field'),
      grandTotal: Yup.number().moreThan(0, 'You must rate at least one area'),
    });

    let yupErrors;

    const { errors } = yield call(validateSchema, saveErrorSchema, reportBody);
    yupErrors = errors || null;

    yield put(InspectFormActions.setFormErrors(yupErrors));

    const errorMessage = [];
    if (reportBody.PID === '') {
      errorMessage.push('PID');
    }

    if (type === 'sent' && reportBody.Date === '') {
      errorMessage.push('Date');
    }
    // verify below only if 'finish' button was pressed
    if (type === 'sent' && reportBody.AccountName === '') {
      errorMessage.push('Location Name');
    }
    if (type === 'sent' && reportBody.grandTotal === 0) {
      errorMessage.push('Facility Rating');
    }

    // display alert with error message, and change loading to false
    const errorLength = errorMessage.length;
    if (errorLength > 0) {
      yield put(InspectFormActions.setWarningModalState(true, errorMessage));
      // alert(finalMessage);
      yield put(InspectFormActions.validationFailure());
    } else {
      // send photos and report to backend
      for (const area of reportBody.Areas) {
        for (const areaPhoto of area.AreaPhotos) {
          if (areaPhoto.File) {
            const res = yield call(saveFileCall, areaPhoto);
            areaPhoto.PathAndFileName = res.Uri;
            // areaPhoto.AreaPhotoId = res.Id;
            areaPhoto.ImageId = res.Id;
          }
        }
      }

      const res = yield call(saveReportCall, reportBody);

      yield put(
        InspectFormActions.saveReportSuccess(
          res,
          type,
          newForm === 'new' || newForm === 'saveas'
        )
      );

      yield put(InspectFormActions.setNeedsSaving(false));

      yield put(InspectFormActions.setInspectionReportId(res.originalId));

      yield put(InspectFormActions.setIsNotSent());

      yield put(InspectFormActions.setIsTemplate(false));
    }
    // }
  } catch (error) {
    yield put(InspectFormActions.saveReportFailure(error));
  }
}

function* handleUpdateTemplate(action: ISaveReportRequest) {
  const { id, shouldChangePage } = action.payload;

  try {
    let currentReport: IStateToProps = yield select(getCurrentReport);

    const franchiseId = getQueryString(window.location.href, 'FranchiseId');

    const AdjustedAreas = currentReport.Areas.map((item: any) => {
      if (item.AreaItems) {
        return {
          ...item,
          AreaItems: item.AreaItems.map((area: IAreaItem) => ({
            ...area,
            Grade: area.Grade - 1,
            Name: capitalizeInput(area.Name),
          })),
          Name: capitalizeInput(item.Name),
        };
      }
      return item;
    });

    const fieldsToCapitalize = [
      'Contact',
      'AccountAddress',
      'AccountName',
      'Owner',
      'Suite',
      'City',
      'FranchiseOwnerName',
    ];
    const fieldsToUppercase = ['PID', 'Zip', 'State'];

    currentReport = mapValues(currentReport, (value, key) => {
      const validValue = value !== null ? value : '';
      let newValue = validValue;
      if (fieldsToCapitalize.includes(key))
        newValue = capitalizeInput(validValue);
      else if (fieldsToUppercase.includes(key))
        newValue = validValue.toUpperCase();
      return newValue;
    });

    const reportBody: IInspectForm = {
      InspectionReportId: id,
      PID: currentReport.PID.toUpperCase(),
      InspectionClassNumber:
        currentReport.InspectionClassNumber === 'Inspection Class #'
          ? ''
          : currentReport.InspectionClassNumber, // so we display blank on the FirstForm component if user has not selected any option for this field
      ContactForm: currentReport.ContactForm,
      Date: currentReport.Date,
      InspectedBy: currentReport.InspectedByInStore,
      // Owner: currentReport.Owner,
      Owner: currentReport.FranchiseOwnerName,
      AccountName: currentReport.AccountName,
      AccountAddress: currentReport.AccountAddress,
      Suite: currentReport.Suite,
      City: currentReport.City,
      State: currentReport.State,
      Zip: currentReport.Zip,
      IsTemplate: true,
      IsCityTemplate: true,
      // FranchiseId: currentReport.FranchiseId,
      FranchiseId: Number(currentReport.MasterCity) || Number(franchiseId),
      DaysCleanedMonday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Mon'),
      DaysCleanedTuesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Tue'),
      DaysCleanedWednesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Wed'),
      DaysCleanedThursday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Thu'),
      DaysCleanedFriday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Fri'),
      DaysCleanedSaturday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sat'),
      DaysCleanedSunday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sun'),
      IsMonthlyCleaning: currentReport.frequencyService === 'monthly',
      TimesPerMonth:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimesPerMonth
          : '',
      TimeOfDay:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimeOfDay
          : '',
      Contact: currentReport.Contact,
      Phone: currentReport.Phone,
      Notes: currentReport.Notes,
      CreatedBy: null,
      FranchiseOwnerName: currentReport.FranchiseOwnerName,
      Time:
        currentReport.frequencyService !== 'monthly'
          ? currentReport.frequency.timeWindow
          : '',
      grandTotal: currentReport.GrandTotal,
      Areas: AdjustedAreas,
      SentStatus: false,
      MasterCity: Number(currentReport.MasterCity) || Number(franchiseId),

      // ClientInfo
      CreatedAt: currentReport.createdAt,
      LastUpdate: currentReport.lastUpdate,
      Address: currentReport.Address,
      MobilePhone: currentReport.MobilePhone,
      County: currentReport.County,
      WalkThruName: currentReport.WalkThruName,
      WalkThruEmail: currentReport.WalkThruEmail,
      WalkThruTitle: currentReport.WalkThruTitle,
      DecisionMakerName: currentReport.DecisionMakerName,
      DecisionMakerEmail: currentReport.DecisionMakerEmail,
      DecisionMakerTitle: currentReport.DecisionMakerTitle,
      SicId: currentReport.sicId,
      SicName: currentReport.sicName,
      ExternalId: currentReport.externalId,
      LocationId: currentReport.locationId,
      WaterStreetUpdateUrl: currentReport.waterStreetUpdateUrl,
      ContactPhoneExtension: currentReport.ContactPhoneExtension,
      ContactPhoneType: currentReport.ContactPhoneType,
      DecisionMakerFirstName: currentReport.DecisionMakerFirstName,
      DecisionMakerLastName: currentReport.DecisionMakerLastName,
      DecisionMakerPhone: currentReport.DecisionMakerPhone,
      DecisionMakerPhoneExtension: currentReport.DecisionMakerPhoneExtension,
      DecisionMakerPhoneType: currentReport.DecisionMakerPhoneType,
      WalkThruFirstName: currentReport.WalkThruFirstName,
      WalkThruLastName: currentReport.WalkThruLastName,
      WalkThruPhone: currentReport.WalkThruPhone,
      WalkThruPhoneExtension: currentReport.WalkThruPhoneExtension,
      WalkThruPhoneType: currentReport.WalkThruPhoneType,
    };

    const saveErrorSchema = Yup.object().shape({
      PID: Yup.string().required('Required field'),
    });

    let yupErrors;

    const { errors } = yield call(validateSchema, saveErrorSchema, reportBody);

    yupErrors = errors || null;

    yield put(InspectFormActions.setFormErrors(yupErrors));

    const errorMessage = [];

    if (reportBody.PID === '') {
      errorMessage.push('PID');
    }

    if (reportBody.AccountName === '') {
      errorMessage.push('Location Name');
    }

    // display alert with error message, and change loading to false
    const errorLength = errorMessage.length;
    if (errorLength > 0) {
      yield put(InspectFormActions.setWarningModalState(true, errorMessage));
      // alert(finalMessage);
      yield put(InspectFormActions.validationFailure());
    } else {
      // send photos and report to backend
      for (const area of reportBody.Areas) {
        for (const areaPhoto of area.AreaPhotos) {
          if (areaPhoto.File) {
            const res = yield call(saveFileCall, areaPhoto);
            areaPhoto.PathAndFileName = res.Uri;
            // areaPhoto.AreaPhotoId = res.Id;
            areaPhoto.ImageId = res.Id;
          }
        }
      }

      const res = yield call(saveReportCall, reportBody);

      if (!shouldChangePage) {
        yield put(
          InspectFormActions.saveReportSuccess(res, 'citytemplate', false)
        );
      }

      yield put(InspectFormActions.setNeedsSaving(false));

      // yield put(InspectFormActions.setInspectionReportId(res.originalId));

      yield toast.info(
        'Template saved successfully! This template is now available for use and can be edited from the Templates Menu.'
      );

      yield put(InspectFormActions.setIsTemplate(false));

      yield put(InspectFormActions.setIsCityTemplate(false));

      yield put(InspectFormActions.setLoadingState(false));
    }
  } catch (error) {
    yield put(InspectFormActions.saveReportFailure(error));
  }
}

function* handleCreateTemplateFromSent(action: ISaveReportRequest) {
  const { id } = action.payload;

  try {
    let currentReport: IStateToProps = yield select(getCurrentReport);

    const franchiseId = getQueryString(window.location.href, 'FranchiseId');

    const AdjustedAreas = currentReport.Areas.map((item: any) => {
      if (item.AreaItems) {
        return {
          ...item,
          AreaItems: item.AreaItems.map((area: IAreaItem) => ({
            ...area,
            Grade: area.Grade - 1,
            Name: capitalizeInput(area.Name),
          })),
          Name: capitalizeInput(item.Name),
        };
      }
      return item;
    });

    const fieldsToCapitalize = [
      'Contact',
      'AccountAddress',
      'AccountName',
      'Owner',
      'Suite',
      'City',
      'FranchiseOwnerName',
    ];
    const fieldsToUppercase = ['PID', 'Zip', 'State'];

    currentReport = mapValues(currentReport, (value, key) => {
      const validValue = value !== null ? value : '';
      let newValue = validValue;
      if (fieldsToCapitalize.includes(key))
        newValue = capitalizeInput(validValue);
      else if (fieldsToUppercase.includes(key))
        newValue = validValue.toUpperCase();
      return newValue;
    });

    const reportBody: IInspectForm = {
      InspectionReportId: id || 0,
      PID: currentReport.PID.toUpperCase(),
      InspectionClassNumber:
        currentReport.InspectionClassNumber === 'Inspection Class #'
          ? ''
          : currentReport.InspectionClassNumber, // so we display blank on the FirstForm component if user has not selected any option for this field
      ContactForm: currentReport.ContactForm,
      Date: currentReport.Date,
      InspectedBy: currentReport.InspectedByInStore,
      // Owner: currentReport.Owner,
      Owner: currentReport.FranchiseOwnerName,
      AccountName: currentReport.AccountName,
      AccountAddress: currentReport.AccountAddress,
      Suite: currentReport.Suite,
      City: currentReport.City,
      State: currentReport.State,
      Zip: currentReport.Zip,
      IsTemplate: true,
      IsCityTemplate: true,
      // FranchiseId: currentReport.FranchiseId,
      FranchiseId: Number(currentReport.MasterCity) || Number(franchiseId),
      DaysCleanedMonday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Mon'),
      DaysCleanedTuesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Tue'),
      DaysCleanedWednesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Wed'),
      DaysCleanedThursday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Thu'),
      DaysCleanedFriday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Fri'),
      DaysCleanedSaturday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sat'),
      DaysCleanedSunday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sun'),
      IsMonthlyCleaning: currentReport.frequencyService === 'monthly',
      TimesPerMonth:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimesPerMonth
          : '',
      TimeOfDay:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimeOfDay
          : '',
      Contact: currentReport.Contact,
      Phone: currentReport.Phone,
      Notes: currentReport.Notes,
      CreatedBy: null,
      FranchiseOwnerName: currentReport.FranchiseOwnerName,
      Time:
        currentReport.frequencyService !== 'monthly'
          ? currentReport.frequency.timeWindow
          : '',
      grandTotal: currentReport.GrandTotal,
      Areas: AdjustedAreas,
      SentStatus: false,
      MasterCity: Number(currentReport.MasterCity) || Number(franchiseId),

      // ClientInfo
      CreatedAt: currentReport.createdAt,
      LastUpdate: currentReport.lastUpdate,
      Address: currentReport.Address,
      MobilePhone: currentReport.MobilePhone,
      County: currentReport.County,
      WalkThruName: currentReport.WalkThruName,
      WalkThruEmail: currentReport.WalkThruEmail,
      WalkThruTitle: currentReport.WalkThruTitle,
      DecisionMakerName: currentReport.DecisionMakerName,
      DecisionMakerEmail: currentReport.DecisionMakerEmail,
      DecisionMakerTitle: currentReport.DecisionMakerTitle,
      SicId: currentReport.sicId,
      SicName: currentReport.sicName,
      ExternalId: currentReport.externalId,
      LocationId: currentReport.locationId,
      WaterStreetUpdateUrl: currentReport.waterStreetUpdateUrl,
      ContactPhoneExtension: currentReport.ContactPhoneExtension,
      ContactPhoneType: currentReport.ContactPhoneType,
      DecisionMakerFirstName: currentReport.DecisionMakerFirstName,
      DecisionMakerLastName: currentReport.DecisionMakerLastName,
      DecisionMakerPhone: currentReport.DecisionMakerPhone,
      DecisionMakerPhoneExtension: currentReport.DecisionMakerPhoneExtension,
      DecisionMakerPhoneType: currentReport.DecisionMakerPhoneType,
      WalkThruFirstName: currentReport.WalkThruFirstName,
      WalkThruLastName: currentReport.WalkThruLastName,
      WalkThruPhone: currentReport.WalkThruPhone,
      WalkThruPhoneExtension: currentReport.WalkThruPhoneExtension,
      WalkThruPhoneType: currentReport.WalkThruPhoneType,
    };

    const saveErrorSchema = Yup.object().shape({
      PID: Yup.string().required('Required field'),
    });

    let yupErrors;

    const { errors } = yield call(validateSchema, saveErrorSchema, reportBody);

    yupErrors = errors || null;

    yield put(InspectFormActions.setFormErrors(yupErrors));

    const errorMessage = [];

    if (reportBody.PID === '') {
      errorMessage.push('PID');
    }

    // display alert with error message, and change loading to false
    const errorLength = errorMessage.length;
    if (errorLength > 0) {
      yield put(InspectFormActions.setWarningModalState(true, errorMessage));
      // alert(finalMessage);
      yield put(InspectFormActions.validationFailure());
    } else {
      // send photos and report to backend
      for (const area of reportBody.Areas) {
        for (const areaPhoto of area.AreaPhotos) {
          if (areaPhoto.File) {
            const res = yield call(saveFileCall, areaPhoto);
            areaPhoto.PathAndFileName = res.Uri;
            // areaPhoto.AreaPhotoId = res.Id;
            areaPhoto.ImageId = res.Id;
          }
        }
      }

      yield call(saveReportCall, reportBody);

      // yield put(
      //   InspectFormActions.saveReportSuccess(res, 'citytemplate', false)
      // );

      yield put(InspectFormActions.setNeedsSaving(false));

      // yield put(InspectFormActions.setInspectionReportId(res.originalId));

      yield toast.info('Your city template was saved successfully');

      yield put(InspectFormActions.setIsTemplate(false));

      yield put(InspectFormActions.setIsCityTemplate(false));

      yield put(InspectFormActions.setLoadingState(false));
    }
  } catch (error) {
    yield put(InspectFormActions.saveReportFailure(error));
  }
}

function* handleCreateDraftFromTemplate() {
  try {
    let currentReport: IStateToProps = yield select(getCurrentReport);

    const franchiseId = getQueryString(window.location.href, 'FranchiseId');

    const AdjustedAreas = currentReport.Areas.map((item: any) => {
      if (item.AreaItems) {
        return {
          ...item,
          AreaItems: item.AreaItems.map((area: IAreaItem) => ({
            ...area,
            Grade: area.Grade - 1,
            Name: capitalizeInput(area.Name),
          })),
          Name: capitalizeInput(item.Name),
        };
      }
      return item;
    });

    const fieldsToCapitalize = [
      'Contact',
      'AccountAddress',
      'AccountName',
      'Owner',
      'Suite',
      'City',
      'FranchiseOwnerName',
    ];
    const fieldsToUppercase = ['PID', 'Zip', 'State'];

    currentReport = mapValues(currentReport, (value, key) => {
      const validValue = value !== null ? value : '';
      let newValue = validValue;
      if (fieldsToCapitalize.includes(key))
        newValue = capitalizeInput(validValue);
      else if (fieldsToUppercase.includes(key))
        newValue = validValue.toUpperCase();
      return newValue;
    });

    const reportBody: IInspectForm = {
      InspectionReportId: 0,
      PID: currentReport.PID.toUpperCase(),
      InspectionClassNumber:
        currentReport.InspectionClassNumber === 'Inspection Class #'
          ? ''
          : currentReport.InspectionClassNumber, // so we display blank on the FirstForm component if user has not selected any option for this field
      ContactForm: currentReport.ContactForm,
      Date: currentReport.Date,
      InspectedBy: currentReport.InspectedByInStore,
      // Owner: currentReport.Owner,
      Owner: currentReport.FranchiseOwnerName,
      AccountName: currentReport.AccountName,
      AccountAddress: currentReport.AccountAddress,
      Suite: currentReport.Suite,
      City: currentReport.City,
      State: currentReport.State,
      Zip: currentReport.Zip,
      IsTemplate: false,
      IsCityTemplate: false,
      // FranchiseId: currentReport.FranchiseId,
      FranchiseId: Number(currentReport.MasterCity) || Number(franchiseId),
      DaysCleanedMonday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Mon'),
      DaysCleanedTuesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Tue'),
      DaysCleanedWednesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Wed'),
      DaysCleanedThursday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Thu'),
      DaysCleanedFriday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Fri'),
      DaysCleanedSaturday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sat'),
      DaysCleanedSunday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sun'),
      IsMonthlyCleaning: currentReport.frequencyService === 'monthly',
      TimesPerMonth:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimesPerMonth
          : '',
      TimeOfDay:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimeOfDay
          : '',
      Contact: currentReport.Contact,
      Phone: currentReport.Phone,
      Notes: currentReport.Notes,
      CreatedBy: currentReport.userId || currentReport.CreatedBy,
      FranchiseOwnerName: currentReport.FranchiseOwnerName,
      Time:
        currentReport.frequencyService !== 'monthly'
          ? currentReport.frequency.timeWindow
          : '',
      grandTotal: currentReport.GrandTotal,
      Areas: AdjustedAreas,
      SentStatus: false,
      MasterCity: Number(currentReport.MasterCity) || Number(franchiseId),

      // ClientInfo
      CreatedAt: currentReport.createdAt,
      LastUpdate: currentReport.lastUpdate,
      Address: currentReport.Address,
      MobilePhone: currentReport.MobilePhone,
      County: currentReport.County,
      WalkThruName: currentReport.WalkThruName,
      WalkThruEmail: currentReport.WalkThruEmail,
      WalkThruTitle: currentReport.WalkThruTitle,
      DecisionMakerName: currentReport.DecisionMakerName,
      DecisionMakerEmail: currentReport.DecisionMakerEmail,
      DecisionMakerTitle: currentReport.DecisionMakerTitle,
      SicId: currentReport.sicId,
      SicName: currentReport.sicName,
      ExternalId: currentReport.externalId,
      LocationId: currentReport.locationId,
      WaterStreetUpdateUrl: currentReport.waterStreetUpdateUrl,
      ContactPhoneExtension: currentReport.ContactPhoneExtension,
      ContactPhoneType: currentReport.ContactPhoneType,
      DecisionMakerFirstName: currentReport.DecisionMakerFirstName,
      DecisionMakerLastName: currentReport.DecisionMakerLastName,
      DecisionMakerPhone: currentReport.DecisionMakerPhone,
      DecisionMakerPhoneExtension: currentReport.DecisionMakerPhoneExtension,
      DecisionMakerPhoneType: currentReport.DecisionMakerPhoneType,
      WalkThruFirstName: currentReport.WalkThruFirstName,
      WalkThruLastName: currentReport.WalkThruLastName,
      WalkThruPhone: currentReport.WalkThruPhone,
      WalkThruPhoneExtension: currentReport.WalkThruPhoneExtension,
      WalkThruPhoneType: currentReport.WalkThruPhoneType,
    };

    const saveErrorSchema = Yup.object().shape({
      PID: Yup.string().required('Required field'),
    });

    let yupErrors;

    const { errors } = yield call(validateSchema, saveErrorSchema, reportBody);

    yupErrors = errors || null;

    yield put(InspectFormActions.setFormErrors(yupErrors));

    const errorMessage = [];

    if (reportBody.PID === '') {
      errorMessage.push('PID');
    }

    // display alert with error message, and change loading to false
    const errorLength = errorMessage.length;
    if (errorLength > 0) {
      yield put(InspectFormActions.setWarningModalState(true, errorMessage));
      // alert(finalMessage);
      yield put(InspectFormActions.validationFailure());
    } else {
      // send photos and report to backend
      for (const area of reportBody.Areas) {
        for (const areaPhoto of area.AreaPhotos) {
          if (areaPhoto.File) {
            const res = yield call(saveFileCall, areaPhoto);
            areaPhoto.PathAndFileName = res.Uri;
            // areaPhoto.AreaPhotoId = res.Id;
            areaPhoto.ImageId = res.Id;
          }
        }
      }

      yield call(saveReportCall, reportBody);

      // yield put(
      //   InspectFormActions.saveReportSuccess(res, 'citytemplate', false)
      // );

      yield put(InspectFormActions.setNeedsSaving(false));

      // yield put(InspectFormActions.setInspectionReportId(res.originalId));

      yield toast.info('Your draft was saved successfully');

      // yield put(InspectFormActions.setIsTemplate(false));

      // yield put(InspectFormActions.setIsCityTemplate(false));

      yield put(InspectFormActions.setLoadingState(false));
    }
  } catch (error) {
    yield put(InspectFormActions.saveReportFailure(error));
  }
}

function* handleCreateTemplateFromDraft() {
  try {
    let currentReport: IStateToProps = yield select(getCurrentReport);

    const franchiseId = getQueryString(window.location.href, 'FranchiseId');

    const AdjustedAreas = currentReport.Areas.map((item: any) => {
      if (item.AreaItems) {
        return {
          ...item,
          AreaItems: item.AreaItems.map((area: IAreaItem) => ({
            ...area,
            Grade: area.Grade - 1,
            Name: capitalizeInput(area.Name),
          })),
          Name: capitalizeInput(item.Name),
        };
      }
      return item;
    });

    const fieldsToCapitalize = [
      'Contact',
      'AccountAddress',
      'AccountName',
      'Owner',
      'Suite',
      'City',
      'FranchiseOwnerName',
    ];
    const fieldsToUppercase = ['PID', 'Zip', 'State'];

    currentReport = mapValues(currentReport, (value, key) => {
      const validValue = value !== null ? value : '';
      let newValue = validValue;
      if (fieldsToCapitalize.includes(key))
        newValue = capitalizeInput(validValue);
      else if (fieldsToUppercase.includes(key))
        newValue = validValue.toUpperCase();
      return newValue;
    });

    const reportBody: IInspectForm = {
      InspectionReportId: 0,
      PID: currentReport.PID.toUpperCase(),
      InspectionClassNumber:
        currentReport.InspectionClassNumber === 'Inspection Class #'
          ? ''
          : currentReport.InspectionClassNumber, // so we display blank on the FirstForm component if user has not selected any option for this field
      ContactForm: currentReport.ContactForm,
      Date: currentReport.Date,
      InspectedBy: currentReport.InspectedByInStore,
      // Owner: currentReport.Owner,
      Owner: currentReport.FranchiseOwnerName,
      AccountName: currentReport.AccountName,
      AccountAddress: currentReport.AccountAddress,
      Suite: currentReport.Suite,
      City: currentReport.City,
      State: currentReport.State,
      Zip: currentReport.Zip,
      IsTemplate: true,
      IsCityTemplate: true,
      // FranchiseId: currentReport.FranchiseId,
      FranchiseId: Number(currentReport.MasterCity) || Number(franchiseId),
      DaysCleanedMonday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Mon'),
      DaysCleanedTuesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Tue'),
      DaysCleanedWednesday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Wed'),
      DaysCleanedThursday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Thu'),
      DaysCleanedFriday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Fri'),
      DaysCleanedSaturday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sat'),
      DaysCleanedSunday:
        currentReport.frequencyService !== 'monthly' &&
        currentReport.frequency.selecteds.includes('Sun'),
      IsMonthlyCleaning: currentReport.frequencyService === 'monthly',
      TimesPerMonth:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimesPerMonth
          : '',
      TimeOfDay:
        currentReport.frequencyService === 'monthly'
          ? currentReport.TimeOfDay
          : '',
      Contact: currentReport.Contact,
      Phone: currentReport.Phone,
      Notes: currentReport.Notes,
      CreatedBy: null,
      FranchiseOwnerName: currentReport.FranchiseOwnerName,
      Time:
        currentReport.frequencyService !== 'monthly'
          ? currentReport.frequency.timeWindow
          : '',
      grandTotal: currentReport.GrandTotal,
      Areas: AdjustedAreas,
      SentStatus: false,
      MasterCity: Number(currentReport.MasterCity) || Number(franchiseId),

      // ClientInfo
      CreatedAt: currentReport.createdAt,
      LastUpdate: currentReport.lastUpdate,
      Address: currentReport.Address,
      MobilePhone: currentReport.MobilePhone,
      County: currentReport.County,
      WalkThruName: currentReport.WalkThruName,
      WalkThruEmail: currentReport.WalkThruEmail,
      WalkThruTitle: currentReport.WalkThruTitle,
      DecisionMakerName: currentReport.DecisionMakerName,
      DecisionMakerEmail: currentReport.DecisionMakerEmail,
      DecisionMakerTitle: currentReport.DecisionMakerTitle,
      SicId: currentReport.sicId,
      SicName: currentReport.sicName,
      ExternalId: currentReport.externalId,
      LocationId: currentReport.locationId,
      WaterStreetUpdateUrl: currentReport.waterStreetUpdateUrl,
      ContactPhoneExtension: currentReport.ContactPhoneExtension,
      ContactPhoneType: currentReport.ContactPhoneType,
      DecisionMakerFirstName: currentReport.DecisionMakerFirstName,
      DecisionMakerLastName: currentReport.DecisionMakerLastName,
      DecisionMakerPhone: currentReport.DecisionMakerPhone,
      DecisionMakerPhoneExtension: currentReport.DecisionMakerPhoneExtension,
      DecisionMakerPhoneType: currentReport.DecisionMakerPhoneType,
      WalkThruFirstName: currentReport.WalkThruFirstName,
      WalkThruLastName: currentReport.WalkThruLastName,
      WalkThruPhone: currentReport.WalkThruPhone,
      WalkThruPhoneExtension: currentReport.WalkThruPhoneExtension,
      WalkThruPhoneType: currentReport.WalkThruPhoneType,
    };

    const saveErrorSchema = Yup.object().shape({
      PID: Yup.string().required('Required field'),
    });

    let yupErrors;

    const { errors } = yield call(validateSchema, saveErrorSchema, reportBody);

    yupErrors = errors || null;

    yield put(InspectFormActions.setFormErrors(yupErrors));

    const errorMessage = [];

    if (reportBody.PID === '') {
      errorMessage.push('PID');
    }

    if (reportBody.AccountName === '') {
      errorMessage.push('Location Name');
    }

    // display alert with error message, and change loading to false
    const errorLength = errorMessage.length;
    if (errorLength > 0) {
      yield put(InspectFormActions.setWarningModalState(true, errorMessage));
      // alert(finalMessage);
      yield put(InspectFormActions.validationFailure());
    } else {
      // send photos and report to backend
      for (const area of reportBody.Areas) {
        for (const areaPhoto of area.AreaPhotos) {
          if (areaPhoto.File) {
            const res = yield call(saveFileCall, areaPhoto);
            areaPhoto.PathAndFileName = res.Uri;
            // areaPhoto.AreaPhotoId = res.Id;
            areaPhoto.ImageId = res.Id;
          }
        }
      }

      yield call(saveReportCall, reportBody);

      // yield put(
      //   InspectFormActions.saveReportSuccess(res, 'citytemplate', false)
      // );

      yield put(InspectFormActions.setNeedsSaving(false));

      // yield put(InspectFormActions.setInspectionReportId(res.originalId));

      yield toast.info(
        'Template saved successfully! This template is now available for use and can be edited from the Templates Menu.'
      );

      yield put(InspectFormActions.setIsTemplate(false));

      yield put(InspectFormActions.setIsCityTemplate(false));

      yield put(InspectFormActions.setLoadingState(false));
    }
  } catch (error) {
    yield put(InspectFormActions.saveReportFailure(error));
  }
}
