import { axiosInstance } from 'core/utils';
import { toast } from 'react-toastify';
import { SupplyDetail, ReferenceDetail } from 'modules/shared/sections/Summary';
import { IAPIResponse } from '../interfaces';
import { postToApi } from '../../BidInfoForm/services/bidInfoService';
import { defaultPostPayload as payload } from 'modules/shared/bidDefaultValues';
import * as Sentry from '@sentry/react';

const env = process.env;

const pathUrl = '/BidSheet/Client';

export const getBidInfoById = (bidInfoId: string) => {
  return axiosInstance.get(`${pathUrl}/${bidInfoId}`);
};

export const getBlankPdfUrl = (bidUniqueId: string) => {
  return axiosInstance.get(`/BidSheet/GetBlankPdfUrl/${bidUniqueId}`);
};

export const postBidInfo = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postSetNotes = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/SetNotes`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const postArchiveBid = (values: any): Promise<IAPIResponse> => {
  return axiosInstance.post(`${pathUrl}/SetArchived`, values, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const getFranchiseById = (franchiseId: string) => {
  // http://anago-forms.api.staging.test.conceptsol.com/api/GetAllFranchises?FranchiseId=2085
  return axiosInstance.get(`/GetAllFranchises?FranchiseId=${franchiseId}`);
};

export const checkIsGeneratingPDF = (uniqueId: string) => {
  return axiosInstance.get(`/BidSheet/CheckIsGeneratingPDF?id=${uniqueId}`);
};

export const isAlive = () => {
  return axiosInstance.get(`/itsalive`);
};

export const isTabIdValid = (uid, tabId) => {
  return axiosInstance.get(
    `${pathUrl}/CheckIfIsValidForThisTab?BidUniqueID=${uid}&TabUniqID=${tabId}`
  );
};

export const getByUniqID = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/GetByUniqID/${uniqueID}`);
};

export const getSignNowDownloadLink = (uniqueID: string) => {
  return axiosInstance.get(`${pathUrl}/getdocumentlink/${uniqueID}`);
};

export const getEasterEggTemplate = () => {
  return axiosInstance.get(`${pathUrl}/GetEasterEgg`);
};

export const getEasterEggSSTemplate = () => {
  return axiosInstance.get(`${pathUrl}/GetEasterEggSS`);
};

export const getProtectionJson = () => {
  return axiosInstance.get(`${pathUrl}/GetProtectionJson`);
};

export const getNextPageBids = ({
  type,
  userId,
  page,
  pid,
  isSpecialty,
  config,
  franchiseId,
}: {
  type: string;
  userId: string | number;
  page: string | number;
  pid?: string | undefined | null;
  isSpecialty?: boolean;
  config?: any;
  franchiseId: string | number;
}) => {
  const getNext = (uri: string) =>
    axiosInstance.get(
      `${pathUrl}/${uri}?id=${userId}&page=${
        page || '0'
      }&franchiseId=${franchiseId}&pid=${pid || ''}${
        isSpecialty ? '&isSS=true' : ''
      }`,
      config
    );

  switch (type) {
    case 'Signed':
      return getNext('GetSignedsByUser');
    case 'Sents':
      return getNext('GetSentsByUser');
    case 'Drafts':
      return getNext('GetDraftsByUser');
    case 'Templates':
      return getNext('GetTemplatesByUser');
    case 'CityTemplates':
      return getNext('GetCityTemplatesByUser');
    default:
      return getNext('GetBidsByUser');
  }
};

export const getBidsByUserId = ({
  userId,
  page,
  pid,
  isSpecialty,
  config,
  franchiseId,
}: {
  userId: string | number;
  page: string | number;
  pid?: string | undefined | null;
  isSpecialty?: boolean;
  config?: any;
  franchiseId: string | number;
}) => {
  return axiosInstance.get(
    `${pathUrl}/GetBidsByUser?id=${userId}&page=${
      page || '0'
    }&franchiseId=${franchiseId}&pid=${pid || ''}${
      isSpecialty ? '&isSS=true' : ''
    }`,
    config
  );
};

export const getSetSigned = (formUniqueId: string): Promise<IAPIResponse> => {
  return axiosInstance.get(`${pathUrl}/SetSignedDocument/${formUniqueId}`);
};

export const setInvalidDocument = (
  formUniqueId: string
): Promise<IAPIResponse> => {
  return axiosInstance.get(
    `${pathUrl}/SetInvalidDocument?guid=${formUniqueId}`
  );
};

export const getSignNowStatus = (
  formUniqueId: string
): Promise<IAPIResponse> => {
  return axiosInstance.get(
    `${pathUrl}/CheckPendDocSpecificBid?guid=${formUniqueId}`
  );
};
/**
 * Conditions:
 * draft = sentStatus === false && isTemplate === false
 * template = sentStatus === false && isTemplate === true
 * sent = sentStatus = true
 * */
export const saveDraft = async (values, userId, isAutoSave = false) => {
  if (
    (values.uid || values.bidInfoId) &&
    (values.isTemplate || values.sentStatus) &&
    !isAutoSave
  ) {
    values.uid = null;
    values.isTemplate = false;
    values.isCityTemplate = false;
  }

  values.sentStatus = false;
  values.generateBlankPDF = false;

  return postToApi({ values, userId, showToastr: !isAutoSave });
};

export const createSignProvider = (userId) =>
  axiosInstance.get(`${pathUrl}/CreateSignProvider?id=${userId}`);
export const verifyUser = (userId) =>
  axiosInstance.get(`${pathUrl}/VerifyUser?id=${userId}`);

export const getPDFCall = async (bidInformationSheetId: string) => {
  const getURL = (id: string) => {
    const href = document.location.href;
    let str =
      env.REACT_APP_BACKEND_PORTAL_URL ||
      'http://anago.app.dev.test.conceptsol.com/';

    // if (href.includes('localhost') || href.includes('app-dev')) {
    //   str = 'http://anago.app.dev.test.conceptsol.com/PDF/Print/[id]/9';
    // } else if (href.includes('app-stag')) {
    //   str = 'http://anago.app.staging.test.conceptsol.com/PDF/Print/[id]/9';
    // } else if (href.includes('app-rc')) {
    //   str = 'http://anago.app.rc.test.conceptsol.com/PDF/Print/[id]/9';
    // } else {
    //   // production
    //   str = 'https://admin.anago.conceptsol.com/PDF/Print/[id]/9';
    // }

    str = str + 'PDF/Print/[id]/9';

    str = str.replace('[id]', id);

    return str;
  };

  const url = getURL(bidInformationSheetId);

  const result = await axiosInstance.get(url);
  return result.Value;
};

export const getPdfBase64 = async (bidId: string) => {
  var base64 = await axiosInstance.get(
    `${pathUrl}/GetPDFBase64?bidId=${bidId}`
  );
  return base64.data;
};

export const getNewPdfBase64 = async (bidUniqueId: string) => {
  var base64 = await axiosInstance.get(
    `${pathUrl}/GetSignedPDFBase64?biduniqueid=${bidUniqueId}`
  );
  return base64.data;
};

export const checkMaintenenceMode = async () => {
  return await await axiosInstance.get(`/CheckMaintenceMode`);
};

export const getEnvironmentUrl = () => {
  const href = document.location.href;

  let str =
    env.REACT_APP_BACKEND_PORTAL_URL ||
    'http://anago.app.dev.test.conceptsol.com/';

  // if (href.includes('localhost') || href.includes('app-dev')) {
  //   str = 'http://anago.app.dev.test.conceptsol.com/';
  // } else if (href.includes('app-stag')) {
  //   str = 'http://anago.app.staging.test.conceptsol.com/';
  // } else if (href.includes('app-rc')) {
  //   str = 'http://anago.app.rc.test.conceptsol.com/';
  // } else {
  //   // production
  //   str = 'https://admin.anago.conceptsol.com/';
  // }

  return str;
};

export const getAttachmentsPdf = (id: string) => {
  return `${getEnvironmentUrl()}Media/${id}.pdf`;
};

export const getInsurancePdf = (franchiseName: string) => {
  return `${getEnvironmentUrl()}Insurances/Insurance_${franchiseName}.pdf`;
};

export const verifyServerUnavailable = (userId: string) => {
  return axiosInstance.get(`/ServerMaintenance?userId=${userId}`);
};

export const getSystemAttachments = () => {
  return axiosInstance.get(`/Franchise/Client/getAllSystemAttachments`);
};

export const getFranchiseAttachments = (franchiseId: string) => {
  return axiosInstance.get(
    `/Franchise/getAllFranchiseAttachments?franchiseId=${franchiseId}`
  );
};

export const getSuppliesDetails = async ({
  supplyId,
  supplyCategory,
  unit,
  brandInfo,
  limit,
  offset,
  page,
  defaultValue,
  sort,
  order,
}: {
  supplyId: string | number;
  supplyCategory: string;
  unit: string;
  brandInfo: string;
  limit: number;
  offset: number;
  page: number;
  defaultValue: boolean | string;
  sort: string;
  order: string;
}) => {
  const { data } = await axiosInstance.get(
    `/Supply/GetSuppliesDetails?supplyId=${supplyId}&supplyCategory=${supplyCategory}&unit=${unit}&brandInfo=${brandInfo}&limit=${limit}&offset=${offset}&page=${page}&defaultSupply=${defaultValue}&sort=${sort}&order=${order}`
  );
  return data;
};

export const getSupplyByFranchiseId = async ({
  franchiseId,
}: {
  franchiseId: string | number;
}) => {
  const { data } = await axiosInstance.get(
    `Supply/GetSupplyByFranchiseID?franchiseId=${franchiseId}`
  );
  return data;
};

export const getBrandInfoList = async (supplyId: number): Promise<string[]> => {
  const { data } = await axiosInstance.get(
    `/Supply/GetBrandInfoFilterBySupply?supplyId=${supplyId}&brandInfo=`
  );
  return data.brandInfoRet.map((item: { BrandInfo: string }) => item.BrandInfo);
};

export const getSupplyCategoryList = async (
  supplyId: number
): Promise<string[]> => {
  const { data } = await axiosInstance.get(
    `/Supply/GetSupplyCategoryFilterBySupply?supplyId=${supplyId}&supplyCategory=`
  );
  return data.supplyCategoryRet.map(
    (item: { SupplyCategory: string }) => item.SupplyCategory
  );
};

export const getUnitList = async (supplyId: number): Promise<string[]> => {
  try {
    const { data, ...rest } = await axiosInstance.get(
      `/Supply/GetUnitFilterBySupply?supplyId=${supplyId}&unit=`
    );
    return data.unitRet.map((item: { Unit: string }) => item.Unit);
  } catch (e) {
    console.log(e);
  }
};

interface InsertSupplyDetailProps {
  SupplyId: number | string;
  SupplyCategory: string;
  BrandInfo: string;
  Unit: string;
  Price: number | string;
  UserId: number | string;
  Order: number;
  Default: boolean;
}

export const insertSupplyDetail = async (
  supplyDetail: InsertSupplyDetailProps
): Promise<SupplyDetail> => {
  try {
    const { data } = await axiosInstance.post(
      `/Supply/InsertSupplyDetail`,
      supplyDetail
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const updateSupplyDetail = async (
  supplyDetail: InsertSupplyDetailProps
): Promise<SupplyDetail> => {
  try {
    const { data } = await axiosInstance.post(
      `/Supply/UpdateSupplyDetail`,
      supplyDetail
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getReferencesDetails = async ({
  referenceId,
  name,
  title,
  company,
  industry,
  limit,
  offset,
  page,
  type,
  defaultValue,
  sort,
  order,
}: {
  referenceId: string | number;
  name: string;
  title: string;
  company: string;
  industry: string;
  limit: number;
  offset: number;
  page: number;
  defaultValue: boolean | string;
  sort: string;
  order: string;
}) => {
  const { data } = await axiosInstance.get(
    `/Reference/GetReferencesDetails?referenceId=${referenceId}&type=${type}&name=${name}&title=${title}&company=${company}&industry=${industry}&limit=${limit}&offset=${offset}&page=${page}&defaultReference=${defaultValue}&sort=${sort}&order=${order}`
  );
  return data;
};

export const getReferenceByFranchiseId = async ({
  franchiseId,
}: {
  franchiseId: string | number;
}) => {
  const { data } = await axiosInstance.get(
    `Reference/GetReferenceByFranchiseID?franchiseId=${franchiseId}`
  );
  return data;
};

export const insertReferenceDetail = async (
  referenceDetail: ReferenceDetail
): Promise<ReferenceDetail> => {
  try {
    const { data } = await axiosInstance.post(
      `/Reference/InsertReferenceDetail`,
      referenceDetail
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getCompanies = async ({
  referenceId,
}: {
  referenceId: string | number;
}): Promise<string[]> => {
  try {
    const { data } = await axiosInstance.get(
      `/Reference/GetCompanyByReference?referenceId=${referenceId}&company=`
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getIndustries = async ({
  referenceId,
}: {
  referenceId: string | number;
}): Promise<string[]> => {
  try {
    const { data } = await axiosInstance.get(
      `/Reference/GetIndustryByReference?referenceId=${referenceId}&industry=`
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getListOrderReferenceTypeByReferenceID = async ({
  referenceId,
}: {
  referenceId: string | number;
}) => {
  const { data } = await axiosInstance.get(
    `/Reference/GetListOrderReferenceTypeByReferenceID?referenceId=${referenceId}`
  );

  const { orderReferenceType } = data;
  const newOrderReferenceType = orderReferenceType.sort(
    (a: any, b: any) => a.Order - b.Order
  );
  return { orderReferenceType: newOrderReferenceType };
};

export const getNames = async ({
  referenceId,
}: {
  referenceId: string | number;
}): Promise<string[]> => {
  try {
    const { data } = await axiosInstance.get(
      `/Reference/GetNameByReference?referenceId=${referenceId}&name=`
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getTitles = async ({
  referenceId,
}: {
  referenceId: string | number;
}): Promise<string[]> => {
  try {
    const { data } = await axiosInstance.get(
      `/Reference/GetTitleByReference?referenceId=${referenceId}&title=`
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const saveNotificationProposalLinkAccess = (uniqueId) => {
  return axiosInstance.post(`${pathUrl}/SaveNotificationProposalLinkAccess`, {
    BidUniqueID: uniqueId,
  });
};
